import palette from 'theming/palette';

export default {
	styleOverrides: {
		root: {
			backgroundColor: palette.secondary.main,
			color: palette.mainTextColor
		}
	}
};
