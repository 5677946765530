import * as XLSX from 'xlsx';
import { useState } from 'react';
import { size } from 'lodash';

export default function useReadExcel() {
	const [fileName, setFileName] = useState('');
	const [error, setError] = useState<boolean>(false);
	const [sheet, setSheet] = useState(null);

	const readFile = (file) => {
		if (
			file?.type !==
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
			file?.type !== 'application/vnd.ms-excel' &&
			file?.type !== '.csv'
		) {
			setError(true);
			return null;
		}

		const reader = new FileReader();

		setFileName(file?.name);

		reader.onload = (evt) => {
			setError(false);

			try {
				const wb = XLSX.read(evt.target.result, {
					type: 'binary'
				});

				const ws = wb.Sheets[wb.SheetNames[0]];

				const data = XLSX.utils.sheet_to_json(ws, {
					header: 0,
					blankrows: false
				});

				if (size(data) > 0) {
					setSheet(data);
				} else {
					setError(true);
				}
			} catch (err) {
				console.log(err);
				setError(true);
			}
		};

		reader.readAsBinaryString(file);
	};

	const reset = () => {
		setError(false);
		setFileName('');
		setSheet(null);
	};

	return { readFile, error, fileName, sheet, reset };
}
