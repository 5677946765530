import React from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import { TOption } from 'components/_forms/AutocompleteField/AutocompleteField';
import { has, isFunction, isObject, isString, map } from 'lodash';
import { generateIdForEntity } from 'helpers';

export interface ChipsFieldProps {
	label?: string;
	name?: string;
	id?: string;
	textFieldProps?: TextFieldProps;
	disableClearable?: boolean;
	handleChange?: (event: any) => void;
	handleBlur?: () => void;
	disabled?: boolean;
	value?: any;
	isReadOnly?: boolean;
}

export default function ChipsField({
	textFieldProps = {},
	label,
	name,
	id,
	disableClearable = true,
	handleChange,
	handleBlur,
	disabled,
	value,
	isReadOnly
}: ChipsFieldProps) {
	const handleChangeValue = (event, newValue) => {
		if (!isFunction(handleChange)) return;

		const transformValues = map(newValue, (item) =>
			isString(item) ? generateIdForEntity({ value: item }) : item
		);

		handleChange(transformValues);
	};

	return (
		<Autocomplete
			multiple
			id={id}
			value={value || []}
			disabled={disabled}
			disableClearable={disableClearable}
			options={[]}
			getOptionLabel={(option) => option?.value || option}
			onChange={handleChangeValue}
			onBlur={handleBlur}
			freeSolo
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					name={name}
					label={label}
					inputProps={{
						...params.inputProps,
						...textFieldProps.inputProps,
						readOnly: isReadOnly
					}}
				/>
			)}
		/>
	);
}
