import React, { useEffect } from 'react';
import { useGenerateActivitiesMutation } from 'store/services/ai.service';
import { DEFAULT_PROMPTS, PROMPT_TYPES_CODE } from '_constants';
import { useAppDispatch, useAppSelector } from 'store';
import {
	activeLangSelector,
	formValuesSelector,
	gptVersionSelector,
	setFormState
} from 'store/slices/masterFormSlice';
import usePrompts from 'hooks/usePrompts';
import { Box, Button, Stack } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { isEmpty, map } from 'lodash';
import ChipsField from 'components/_forms/ChipsField';
import LoaderWithData from 'components/_layout/LoaderWithData';
import LoadIndicator from 'components/_layout/LoadIndicator';
import { useTranslation } from 'react-i18next';

export default function AdditionalInfoSection() {
	const { t } = useTranslation();

	const [
		generateActivities,
		{ isLoading: isLoadingActivities, data: activitiesData }
	] = useGenerateActivitiesMutation();
	const dispatch = useAppDispatch();

	const gptVersion = useAppSelector(gptVersionSelector);
	const formValues = useAppSelector(formValuesSelector);
	const activeLang = useAppSelector(activeLangSelector);

	const { control, setValue } = useFormContext();
	const valueActivities = useWatch({ name: 'companyActivities' });

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const { promptsList, lastPrompt, isLoadingPromptsList, createPrompt } =
		usePrompts({
			typeCode: PROMPT_TYPES_CODE.ACTIVITIES
		});

	useEffect(() => {
		dispatch(setFormState({ companyActivities: valueActivities }));
	}, [valueActivities]);

	useEffect(() => {
		if (!activitiesData || isEmpty(activitiesData?.companyActivities))
			return;

		const activitiesOptions = map(
			activitiesData?.companyActivities,
			(item) => `(${item?.classifier}) ${item?.classificationDescription}`
		);

		setValue('companyActivities', activitiesOptions);
	}, [activitiesData]);
	const handleGenerateActivities = () => {
		generateActivities({
			prompt: lastPrompt?.prompt || DEFAULT_PROMPTS.ACTIVITIES,
			model: gptVersion,
			activeLang: activeLang,
			body: {
				companyName: formValues?.companyInfo,
				companyUrl: formValues.companyUrl,
				country: formValues?.companyCountry
			}
		});
	};

	useEffect(() => {
		if (!isEmpty(formValues?.companyActivities) || isLoadingPromptsList)
			return;

		handleGenerateActivities();
	}, [isLoadingPromptsList]);

	return (
		<Box>
			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant='h1'
						component='h2'
						color='mainTextColor'
					>
						{t('activities')}
					</Typography>
				</Grid>
			</Grid>

			<Box mt={6} sx={{ position: 'relative' }}>
				{formValues?.companyActivities && isLoadingActivities ? (
					<LoaderWithData />
				) : null}

				{!formValues?.companyActivities && isLoadingActivities ? (
					<LoadIndicator />
				) : (
					<Box>
						<Controller
							render={({ field }) => (
								<ChipsField
									name={field?.name}
									value={field?.value}
									handleBlur={field?.onBlur}
									handleChange={field?.onChange}
									isReadOnly
									label={t('activities')}
								/>
							)}
							name='companyActivities'
							control={control}
						/>
					</Box>
				)}
			</Box>

			<Box mt={3.5} mb={2}>
				<Stack direction={{ md: 'row', xs: 'column' }} spacing={4}>
					<Button
						onClick={handleGenerateActivities}
						variant='contained'
						size='large'
						fullWidth
						color='secondary'
						disabled={isLoadingActivities}
					>
						{t('try_again')}
					</Button>

					<Button
						onClick={handleNextStep}
						variant='contained'
						size='large'
						color='primary'
						fullWidth
						disabled={isLoadingActivities}
					>
						{t('save_and_continue')}
					</Button>
				</Stack>
			</Box>
		</Box>
	);
}
