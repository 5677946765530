import React, { useEffect } from 'react';
import {
	Autocomplete,
	Box,
	Checkbox,
	TextField,
	TextFieldProps
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { find, isEmpty, isFunction, values } from 'lodash';

export type TOption = {
	value: unknown;
	label: string;
};

export interface IAutocompleteField {
	options: TOption[];
	label?: string;
	limitTags?: number;
	name?: string;
	id?: string;
	textFieldProps?: TextFieldProps;
	disableClearable?: boolean;
	isMultiple?: boolean;
	handleChange?: (event: any) => void;
	handleBlur?: () => void;
	disabled?: boolean;
	value?: any;
	groupBy?: (option) => string;
	renderTags?: (value: any[], getTagProps: any) => React.ReactNode;
	freeSolo?: boolean;
	customIsOptionEqualToValue?: (option: any, currentOption: any) => boolean;
}

export default function AutocompleteField({
	options,
	textFieldProps = {},
	label,
	name,
	id,
	disableClearable = true,
	isMultiple = false,
	handleChange,
	handleBlur,
	disabled,
	limitTags = 4,
	value,
	groupBy,
	freeSolo,
	customIsOptionEqualToValue,
	renderTags
}: IAutocompleteField) {
	const handleChangeValue = (event, newValue) => {
		if (!isFunction(handleChange)) return;

		handleChange(newValue);
	};

	useEffect(() => {
		if (isEmpty(value) || !value?.value || freeSolo) return;

		const findActiveOption = find(
			options,
			(item) =>
				item?.value === value?.value ||
				JSON.stringify(item?.value) === JSON.stringify(value?.value)
		);

		if (findActiveOption) return;

		handleChange(null);
	}, [options]);

	const isOptionEqualToValue = (option, currentOption) => {
		if (isFunction(customIsOptionEqualToValue))
			return customIsOptionEqualToValue(option, currentOption);

		return option && option?.value === currentOption?.value;
	};

	return (
		<Autocomplete
			freeSolo={freeSolo}
			id={id}
			multiple={isMultiple}
			disableCloseOnSelect={isMultiple}
			limitTags={limitTags}
			options={options}
			disabled={disabled}
			value={value}
			onChange={handleChangeValue}
			onBlur={handleBlur}
			groupBy={groupBy}
			autoHighlight
			disableClearable={disableClearable}
			isOptionEqualToValue={isOptionEqualToValue}
			getOptionLabel={(option) => option.label || value || ''}
			renderOption={(props, option, { selected }) => (
				<Box component='li' {...props}>
					{isMultiple && (
						<Checkbox
							icon={
								<CheckBoxOutlineBlankIcon
									sx={{
										color: (theme) =>
											theme.palette.inputBorder
									}}
									fontSize='small'
								/>
							}
							checkedIcon={<CheckBoxIcon fontSize='small' />}
							style={{ marginRight: 8 }}
							checked={selected}
						/>
					)}
					{option.label}
				</Box>
			)}
			renderTags={renderTags}
			renderInput={(params) => (
				<TextField
					{...params}
					{...textFieldProps}
					name={name}
					variant='outlined'
					label={label}
					inputProps={{
						...params.inputProps,
						autoComplete: 'new-password' // disable autocomplete and autofill
					}}
				/>
			)}
		/>
	);
}
