import palette from '../palette';

export default {
	styleOverrides: {
		root: {},

		paper: {
			background: palette.paperColor
		},

		option: {
			color: palette.mainTextColor,
			padding: '6px 24px',

			'&.Mui-focused.MuiAutocomplete-option': {
				backgroundColor: palette.autocompleteFocus
			}
		},

		tag: {
			color: palette.mainTextColor
		},

		groupLabel: {
			color: palette.mainTextColor,
			backgroundColor: palette.oddTableColor
		},

		noOptions: {
			color: palette.mainTextColor
		}
	}
};
