import React, { ReactNode, useEffect, useState } from 'react';
import {
	Box,
	Grid,
	GridProps,
	Stack,
	StackProps,
	Theme,
	Typography,
	TypographyProps
} from '@mui/material';
import { isEmpty, isString, isNumber, map, isFunction } from 'lodash';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/material/styles';
import { ITableColumn } from 'components/_layout/Table/components/TableColumn';
import { ITableContext, TableContext } from 'components/_layout/Table/Table';
import useGetContext from 'hooks/useGetContext';

export const BoxIcon = styled(Box)(({ theme }) => ({
	// color: theme.palette.darkGray,
	fontSize: 13,
	cursor: 'pointer'

	// '&:hover': {
	// 	color: theme.palette.lightGray
	// }
}));

export type THeaderColumnLabel = string | number | ReactNode;

export interface IHeaderColumn extends Omit<ITableColumn, 'children'> {
	nameParamOfSorting?: string;
	label: THeaderColumnLabel;
	align?: TypographyProps['align'];
	justifyStack?: StackProps['justifyContent'];
	typographyVariant?: TypographyProps['variant'];
	typographyColor?: TypographyProps['color'];
	sxGridItem?: GridProps['sx'];
	fixedWidth?: number;
}

export interface ITableHeader {
	columns: IHeaderColumn[];
	spacingBetweenColumn?: GridProps['spacing'];
	sxRow?: GridProps['sx'];
}

export type TSortMode = 'asc' | 'desc' | null;

export default function TableHeader({
	columns,
	sxRow = {},
	spacingBetweenColumn = 2
}: ITableHeader) {
	const [activeOptionSort, setActiveOptionSort] = useState(null);
	const [activeSortMode, setActiveSortMode] = useState(null);
	const { callbackSorting, disableSorting }: ITableContext =
		useGetContext(TableContext);

	const resetSort = () => {
		setActiveOptionSort(null);
		setActiveSortMode(null);
		if (isFunction(callbackSorting)) callbackSorting(null, null);
	};

	useEffect(() => {
		if (activeOptionSort || activeSortMode) {
			resetSort();
		}
	}, [columns]);

	const handleChangeSort = (value: string, mode: TSortMode) => {
		if (value === activeOptionSort && mode === activeSortMode) {
			resetSort();
		} else {
			setActiveOptionSort(value);
			setActiveSortMode(mode);

			if (isFunction(callbackSorting)) callbackSorting(value, mode);
		}
	};

	return (
		<Box
			p={2}
			sx={{
				borderBottom: (theme) =>
					`1px solid ${theme.palette.inputBorder}`,
				...sxRow
			}}
		>
			<Grid container alignItems='center' spacing={spacingBetweenColumn}>
				{!isEmpty(columns) &&
					map(columns, (column, key) => (
						<Grid
							item
							sx={column?.sxGridItem}
							xs={column.columnSizeDefault}
							{...column.responsiveColumnSizes}
							key={key}
						>
							<Stack
								direction='row'
								alignItems='center'
								sx={{
									width: column?.fixedWidth
										? column?.fixedWidth
										: 'auto'
								}}
								justifyContent={column?.justifyStack}
								spacing={1}
							>
								{column?.nameParamOfSorting &&
									!disableSorting && (
										<Stack>
											<BoxIcon
												onClick={() =>
													handleChangeSort(
														column.nameParamOfSorting,
														'asc'
													)
												}
												sx={
													activeOptionSort ===
														column?.nameParamOfSorting &&
													activeSortMode === 'asc'
														? {
																color: (
																	theme: Theme
																) =>
																	theme
																		.palette
																		.secondary
																		.main
														  }
														: {}
												}
											>
												<ArrowDropUpIcon />
											</BoxIcon>

											<BoxIcon
												onClick={() =>
													handleChangeSort(
														column.nameParamOfSorting,
														'desc'
													)
												}
												sx={
													activeOptionSort ===
														column?.nameParamOfSorting &&
													activeSortMode === 'desc'
														? {
																color: (
																	theme: Theme
																) =>
																	theme
																		.palette
																		.secondary
																		.main
														  }
														: {}
												}
											>
												<ArrowDropDownIcon />
											</BoxIcon>
										</Stack>
									)}
								<Box>
									{isString(column.label) ||
									isNumber(column.label) ? (
										<Typography
											variant={
												column?.typographyVariant ||
												'h5'
											}
											color={
												column?.typographyColor ||
												'primaryGray'
											}
											align={column?.align || 'left'}
										>
											{column?.label}
										</Typography>
									) : (
										column?.label
									)}
								</Box>
							</Stack>
						</Grid>
					))}
			</Grid>
		</Box>
	);
}
