import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { isEmpty, has } from 'lodash';

function collectSubCategoryNames(category, result) {
	if (!isEmpty(category) && has(category, 'sub_categories')) {
		category.sub_categories.forEach((subCategory) => {
			const { name, description } = subCategory;
			result.push({ name, description });
			collectSubCategoryNames(subCategory, result);
		});
	}
}

export default function Categories(props) {
	const { categories } = props;
	const subCategoryNames = [];
	categories.forEach((category) => {
		collectSubCategoryNames(category, subCategoryNames);
	});

	return (
		<List
			sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
		>
			{subCategoryNames.map((d) => (
				<div>
					<ListItem alignItems='flex-start'>
						<ListItemAvatar>
							<Avatar
								alt={d.name}
								src='/static/images/avatar/1.jpg'
							/>
						</ListItemAvatar>
						<ListItemText
							primary={d.name}
							secondary={
								<React.Fragment>{d.description}</React.Fragment>
							}
						/>
					</ListItem>
					<Divider variant='inset' component='li' />
				</div>
			))}
		</List>
	);
}
