import React, { createContext, ReactElement, ReactNode, useMemo } from 'react';
import { TSortMode } from 'components/_layout/Table/components/TableHeader/TableHeader';
import TableRow from 'components/_layout/Table/components/TableRow';
import TableColumn from 'components/_layout/Table/components/TableColumn';
import TableHeader from 'components/_layout/Table/components/TableHeader';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';

export interface ITableContext {
	disableSorting?: boolean;
	callbackSorting?: (option: string, mode: TSortMode) => void;
}
export const TableContext = createContext<ITableContext>({
	disableSorting: false,
	callbackSorting: null
});

export interface ITable {}

interface ITableCompound {
	Row?: typeof TableRow;
	Column?: typeof TableColumn;
	Header?: typeof TableHeader;
}

export type TTableProps = {
	disableSorting?: boolean;
	callbackSorting?: (option: string, mode: TSortMode) => void;
	children: ReactNode;
};

export const StyledTableContainer = styled(Box)(({ theme }) => ({
	'& > div:nth-of-type(odd)': {
		backgroundColor: theme.palette.oddTableColor
	},

	'& > div:nth-of-type(even)': {
		backgroundColor: theme.palette.evenTableColor
	}
}));

function Table({
	disableSorting = false,
	callbackSorting,
	children
}: TTableProps): ReactElement<ITable> & ITableCompound {
	const contextValues = useMemo(
		() => ({ disableSorting, callbackSorting }),
		[disableSorting, callbackSorting]
	);

	return (
		<TableContext.Provider value={contextValues}>
			<StyledTableContainer>{children}</StyledTableContainer>
		</TableContext.Provider>
	);
}

Table.Row = TableRow;
Table.Column = TableColumn;
Table.Header = TableHeader;

export default Table;
