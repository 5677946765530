import React from 'react';
import { IVerticalLinearStepper } from 'components/VerticalLinearStepper';
import { Box, Button, MobileStepper as MuiMobileStepper } from '@mui/material';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

export default function MobileStepper({ steps }: IVerticalLinearStepper) {
	const { t } = useTranslation();

	const { activeStep, handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const { length } = steps;

	return (
		<Box>
			<Box pb={9}>
				{steps[activeStep]?.component ? (
					<Box>{steps[activeStep]?.component}</Box>
				) : null}
			</Box>

			<MuiMobileStepper
				variant='text'
				steps={steps.length}
				position='bottom'
				activeStep={activeStep}
				nextButton={
					<Button
						sx={{ color: (theme) => theme.palette.mainTextColor }}
						size='small'
						onClick={handleNextStep}
						disabled={activeStep === length - 1}
					>
						{t('next')}
						<KeyboardArrowRight />
					</Button>
				}
				backButton={
					<Button
						sx={{ color: (theme) => theme.palette.mainTextColor }}
						size='small'
						onClick={handlePrevStep}
						disabled={activeStep === 0}
					>
						<KeyboardArrowLeft />
						{t('back')}
					</Button>
				}
			/>
		</Box>
	);
}
