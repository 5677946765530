import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import {
	gptVersionSelector,
	setGptVersion
} from 'store/slices/masterFormSlice';

export default function ButtonAppBar({ settings, setSettings }) {
	const gptVersion = useAppSelector(gptVersionSelector);

	const dispatch = useAppDispatch();

	const handleChangeGptVersion = (e) => {
		dispatch(setGptVersion(e.target.value));

		setSettings({
			...settings,
			model: e.target.value
		});
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='static'>
				<Toolbar>
					<IconButton
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						variant='h6'
						component='div'
						sx={{ flexGrow: 1 }}
					>
						AI ecommerce master
					</Typography>
					<FormControl>
						<InputLabel id='demo-simple-select-label'>
							Model
						</InputLabel>
						<Select
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							value={gptVersion}
							label='Model'
							onChange={handleChangeGptVersion}
						>
							<MenuItem value='gpt-3.5-turbo'>
								GPT-3.5 Turbo
							</MenuItem>
							<MenuItem value='gpt-4'>GPT-4</MenuItem>
							<MenuItem value='gpt-4-1106-preview'>
								GPT-4 Turbo
							</MenuItem>
						</Select>
					</FormControl>
					<Button color='inherit'>Login</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
