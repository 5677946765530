import React from 'react';
import { Box, Chip, Skeleton, Stack } from '@mui/material';
import { map } from 'lodash';
import Table from 'components/_layout/Table';

export interface ProductRowSkeletonProps {
	quantity: number;
}

export default function ProductRowSkeleton({
	quantity = 1
}: ProductRowSkeletonProps) {
	return (
		<Table>
			{map([...Array(quantity)], (row, key) => (
				<Table.Row key={key}>
					<Table.Column columnSizeDefault={0.5}>
						<Skeleton variant='text' height={30} />
					</Table.Column>

					<Table.Column columnSizeDefault={3}>
						<Stack direction='row' spacing={2} alignItems='center'>
							<Skeleton
								variant='circular'
								sx={{ minWidth: 40 }}
								width={40}
								height={40}
							/>

							<Box sx={{ width: '100%' }}>
								<Skeleton variant='text' height={30} />
							</Box>
						</Stack>
					</Table.Column>

					<Table.Column columnSizeDefault>
						<Skeleton variant='text' height={30} />
					</Table.Column>

					<Table.Column columnSizeDefault={1.5}>
						<Skeleton variant='text' height={30} />
					</Table.Column>

					<Table.Column columnSizeDefault={1.5}>
						<Skeleton variant='text' height={30} />
					</Table.Column>

					<Table.Column columnSizeDefault={1}>
						<Skeleton variant='text' height={30} />
					</Table.Column>
				</Table.Row>
			))}
		</Table>
	);
}
