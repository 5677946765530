import * as React from 'react';
import Box from '@mui/material/Box';

export default function ProductDetail({ productState }) {
	return (
		<Box
			component='form'
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' }
			}}
			noValidate
			autoComplete='off'
		>
			<div>
				{/* <div><pre>{JSON.stringify(resCategories, null, 2) }</pre></div> */}
				<pre>{JSON.stringify(productState, null, 2)}</pre>
			</div>
		</Box>
	);
}
