import React from 'react';
import { IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

export interface EditOrDeleteBoxProps {
	handleDelete: () => void;
	handleEdit: () => void;
	isDisabled?: boolean;
}

export default function EditOrDeleteBox({
	handleDelete,
	handleEdit,
	isDisabled
}: EditOrDeleteBoxProps) {
	return (
		<Stack direction='row'>
			<IconButton
				disabled={isDisabled}
				sx={{ opacity: isDisabled ? 0.6 : 1 }}
				aria-label='edit'
				size='small'
				onClick={handleEdit}
			>
				<EditIcon
					sx={{ color: (theme) => theme.palette.mainTextColor }}
				/>
			</IconButton>

			<IconButton
				sx={{ opacity: isDisabled ? 0.6 : 1 }}
				disabled={isDisabled}
				aria-label='edit'
				size='small'
				onClick={handleDelete}
			>
				<CloseIcon
					sx={{ color: (theme) => theme.palette.mainTextColor }}
				/>
			</IconButton>
		</Stack>
	);
}
