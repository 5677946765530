import React, { ReactNode } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import Box from '@mui/material/Box';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';

export type IStep = {
	label: string;
	component?: ReactNode;
	description?: string;
	subComponent?: ReactNode;
};

export interface IVerticalLinearStepper {
	steps: IStep[];
}

export default function VerticalLinearStepper({
	steps
}: IVerticalLinearStepper) {
	const { t } = useTranslation();

	const {
		activeStep,
		handleChangeStep,
		completedSteps,
		nextStep
	}: IStepperContext = useGetContext(StepperContext);

	const l = steps.length - 1;

	const handleClickStep = (index: number) => () => {
		handleChangeStep(index);
	};

	return (
		<Box>
			{steps[activeStep]?.subComponent ?? null}

			<Box sx={{ position: 'relative', zIndex: 3 }}>
				<Stepper activeStep={activeStep} orientation='vertical'>
					{map(steps, (step, index) => (
						<Step
							key={`${index}_${step.label}`}
							completed={completedSteps[index]}
						>
							<StepLabel
								optional={
									index === l ? (
										<Typography
											variant='caption'
											color='primaryGray'
										>
											{t('last_step_stepper')}
										</Typography>
									) : null
								}
							>
								<Box
									sx={{
										cursor: 'pointer',
										fontWeight:
											nextStep === index &&
											completedSteps[nextStep - 1]
												? 600
												: 400
									}}
									onClick={
										completedSteps[index] ||
										(nextStep === index &&
											completedSteps[nextStep - 1])
											? handleClickStep(index)
											: null
									}
									component='span'
								>
									{step.label}
								</Box>
							</StepLabel>

							<StepContent
								TransitionProps={{ unmountOnExit: false }}
							>
								<Box
									sx={{ position: 'relative', zIndex: 3 }}
									pl={13}
								>
									{step.description && (
										<Typography>
											{step.description}
										</Typography>
									)}
									{step.component && index === activeStep ? (
										<Box>{step.component}</Box>
									) : null}
								</Box>
							</StepContent>
						</Step>
					))}
				</Stepper>
			</Box>
		</Box>
	);
}
