import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';

import {
	persistReducer,
	persistStore,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER
} from 'redux-persist';
import { baseApi } from 'store/services/base.service';
import masterFormSlice from 'store/slices/masterFormSlice';
import { aiApi } from 'store/services/ai.service';
import { promptsApi } from 'store/services/prompts.service';

const persistConfig = {
	key: 'masterForm',
	storage
};

const persistedMasterForm = persistReducer(persistConfig, masterFormSlice);

export function setUpStore() {
	const store = configureStore({
		reducer: {
			masterFormSlice: persistedMasterForm,
			[baseApi.reducerPath]: baseApi.reducer,
			[aiApi.reducerPath]: aiApi.reducer,
			[promptsApi.reducerPath]: promptsApi.reducer
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: [
						FLUSH,
						REHYDRATE,
						PAUSE,
						PERSIST,
						PURGE,
						REGISTER
					]
				}
			}).concat(baseApi.middleware)
	});

	setupListeners(store.dispatch);

	return store;
}

export const store = setUpStore();

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<any> = useSelector;

export const persistMasterForm = persistStore(store);
