class gptAPI {
	// API_URL = 'http://127.0.0.1:8000/api-public/integration'
	API_URL = 'https://integration.iam-trade.ch/api-public/integration';

	generateCategories = (data, setState) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		};
		setState({ loading: true });
		fetch(this.API_URL + '/ai/generate_categories', requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					setState({
						loading: false,
						result: result,
						resCategories: result?.result?.categories
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setState({
						loading: false,
						error
					});
				}
			);
	};

	generateProducts = (data, setState) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		};
		setState({ loading: true });
		fetch(this.API_URL + '/ai/generate_products', requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					setState({
						loading: false,
						result: result,
						resProducts: result?.result?.products
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setState({
						loading: false,
						error
					});
				}
			);
	};

	generateFilters = (data, setState) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		};
		setState({ loading: true });
		fetch(this.API_URL + '/ai/generate_filters', requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					setState({
						loading: false,
						result: result,
						resFilters: result?.result?.filters
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setState({
						loading: false,
						error
					});
				}
			);
	};

	getProductData = (data, setState) => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		};
		setState({ loading: true });
		fetch(this.API_URL + '/ai/product', requestOptions)
			.then((res) => res.json())
			.then(
				(result) => {
					setState({
						loading: false,
						result: result,
						resProduct: result?.result?.product
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					setState({
						loading: false,
						error
					});
				}
			);
	};
}

const gpt = new gptAPI();
export default gpt;
