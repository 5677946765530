import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
	activeLangSelector,
	filtersSelector,
	formValuesSelector,
	gptVersionSelector,
	removeFilter,
	updateFilter
} from 'store/slices/masterFormSlice';
// import { useWatch } from 'react-hook-form';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import {
	useGenerateFiltersMutation,
	useReplaceFilterMutation
} from 'store/services/ai.service';
import { IHeaderColumn } from 'components/_layout/Table/components/TableHeader';
import Table from 'components/_layout/Table';
import { Box, Button, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoaderWithData from 'components/_layout/LoaderWithData';
import LoadIndicator from 'components/_layout/LoadIndicator';
// import { findIndex, forEach, isEmpty, map } from 'lodash';
import { isEmpty, map } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditPrompt from 'components/EditPrompt';
import { DEFAULT_PROMPTS, PROMPT_TYPES_CODE } from '_constants';
import FilterItem from 'components/FilterItem';
import useToggle from 'hooks/useToggle';
import EditFilterModal, {
	IFormValuesEditFilters
} from 'components/_modals/EditFilterModal';
import { IFilter } from 'types';
import usePrompts from 'hooks/usePrompts';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

export default function PropertiesSection() {
	const { t } = useTranslation();

	const filters = useAppSelector(filtersSelector);
	const gptVersion = useAppSelector(gptVersionSelector);
	const activeLang = useAppSelector(activeLangSelector);

	const dispatch = useAppDispatch();

	const formValues = useAppSelector(formValuesSelector);

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const [generateFilters, { isLoading }] = useGenerateFiltersMutation();
	const [replaceFilter] = useReplaceFilterMutation();

	const [editedFilter, setEditedFilter] = useState(null);
	const [updatingFilters, setUpdatingFilters] = useState({});

	const {
		open: isOpenFilterModal,
		handleOpen: handleOpenFilterModal,
		handleClose: handleCloseFilterModal
	} = useToggle();

	const { promptsList, lastPrompt, createPrompt, isLoadingPromptsList } =
		usePrompts({
			typeCode: PROMPT_TYPES_CODE.PROPERTIES
		});

	const handleGenerateFilters = () => {
		generateFilters({
			description: `for specifications ${JSON.stringify(
				formValues?.companyActivities
			)}`,
			activeLang: activeLang,
			prompt: `${
				formValues.promptFilters ||
				lastPrompt?.prompt ||
				DEFAULT_PROMPTS.FILTERS
			}, not include specifications in result`,
			model: gptVersion
		});
	};

	useEffect(() => {
		if (!isEmpty(filters) || isLoadingPromptsList) return;

		handleGenerateFilters();
	}, [isLoadingPromptsList]);

	const handleEditFilter = (id?: string) => {
		setEditedFilter(filters?.[id]);
		handleOpenFilterModal();
	};

	const handleCreateFilter = (id?: string) => () => {
		setEditedFilter(filters?.[id]);
		handleOpenFilterModal();
	};

	const handleSaveEdit = (data: IFormValuesEditFilters) => {
		const filterItem = {
			values: data?.filterValues,
			name: data?.filterName,
			id: data?.id
		};

		dispatch(updateFilter(filterItem));
	};

	const handleRemoveFilter = (id: string) => {
		if (!id) return;
		dispatch(removeFilter(id));
	};

	const handleReplaceFilter = (id: string) => {
		if (!id) return;
		const filterItem = filters?.[id];

		if (!filterItem) return;

		setUpdatingFilters((prevState) => ({ ...prevState, [id]: true }));

		replaceFilter({
			model: gptVersion,
			id: filterItem?.id,
			activeLang: activeLang,
			specifications: formValues?.companyActivities,
			companyName: formValues?.companyInfo,
			name: filterItem?.name
		}).finally(() =>
			setUpdatingFilters((prevState) => ({ ...prevState, [id]: false }))
		);
	};

	const headerConfig: IHeaderColumn[] = useMemo(
		() => [
			{
				columnSizeDefault: 1,
				label: '#',
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 3,
				label: t('table_name'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: true,
				label: t('table_values'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 2,
				label: '',
				justifyStack: 'right'
			}
		],
		[]
	);

	return (
		<Box>
			<Box mb={3}>
				{!isLoadingPromptsList && (
					<EditPrompt
						name='promptFilters'
						callbackSavePrompt={createPrompt}
						promptsList={promptsList}
						defaultValue={
							lastPrompt?.prompt || DEFAULT_PROMPTS.FILTERS
						}
						externalValue={
							formValues?.promptFilters ||
							lastPrompt?.prompt ||
							DEFAULT_PROMPTS.FILTERS
						}
					/>
				)}
			</Box>

			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Typography variant='h1' component='h2' color='mainTextColor'>
				{t('properties_section_title')}
			</Typography>

			<Box mt={5} position='relative'>
				{!isEmpty(filters) && isLoading ? <LoaderWithData /> : null}

				{isEmpty(filters) && isLoading ? (
					<LoadIndicator />
				) : (
					<Box sx={{ overflowX: 'auto' }}>
						<Box sx={{ minWidth: 600 }}>
							<Table>
								<Table.Header
									spacingBetweenColumn={4}
									columns={headerConfig}
								/>

								{filters
									? map(
											Object.values(filters),
											(filter: IFilter, key) => (
												<FilterItem
													handleEditFilter={
														handleEditFilter
													}
													handleReplaceFilter={
														handleReplaceFilter
													}
													handleRemoveFilter={
														handleRemoveFilter
													}
													isUpdatingFilter={
														updatingFilters?.[
															filter?.id
														]
													}
													key={filter?.name || key}
													id={filter?.id}
													name={filter?.name}
													numberLine={key + 1}
													values={filter?.values}
												/>
											)
									  )
									: null}
							</Table>
						</Box>
					</Box>
				)}

				<Box
					sx={{
						position: 'absolute',
						top: 1,
						right: 0,
						width: 45
					}}
				>
					<IconButton
						sx={{
							color: (theme) => theme.palette.mainTextColor,
							border: 1,
							borderRadius: 1,
							backgroundColor: (theme) => theme.palette.bodyColor,
							width: 45,
							height: 45
						}}
						onClick={handleCreateFilter()}
						size='large'
					>
						<AddIcon />
					</IconButton>
				</Box>
			</Box>

			<Stack mt={10} mb={2} direction='row' spacing={5}>
				<Button
					fullWidth
					onClick={handleGenerateFilters}
					disabled={isLoading}
					variant='contained'
					color='secondary'
					size='large'
				>
					{t('try_again')}
				</Button>

				<Button
					fullWidth
					onClick={handleNextStep}
					disabled={isLoading}
					variant='contained'
					color='primary'
					size='large'
				>
					{t('save_and_continue')}
				</Button>
			</Stack>

			<EditFilterModal
				activeFilter={editedFilter}
				isOpen={isOpenFilterModal}
				handleSave={handleSaveEdit}
				handleClose={handleCloseFilterModal}
			/>
		</Box>
	);
}
