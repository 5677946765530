import { forEach, isEmpty, map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ICategory } from 'types';

export const generateIdForArrayEntity = (array: any) => {
	if (isEmpty(array)) return [];

	return map(array, (item) => (item?.id ? item : { ...item, id: uuidv4() }));
};

export const generateIdForEntity = (entity: any) => {
	if (isEmpty(entity)) return {};

	return entity?.id ? entity : { ...entity, id: uuidv4() };
};

export const getAllCategoriesRecursive = (category: ICategory) => {
	const items: ICategory[] = [];

	items.push({ ...category, sub_categories: undefined });

	forEach(category?.sub_categories, (subItem) =>
		getAllCategoriesRecursive(subItem)
	);

	return items;
};

export const transformRecursiveEntities = (
	items: any,
	recursivePath,
	parentItemId = ''
) => {
	const newItems = [];

	const recursiveFunction = (recursiveItems: any, parentId: string = '') => {
		forEach(recursiveItems, (item) => {
			newItems.push({ ...item, parentId: parentId, [recursivePath]: [] });

			if (item?.[recursivePath]) {
				recursiveFunction(item[recursivePath], item?.id);
			}
		});
	};

	recursiveFunction(items, parentItemId);

	return newItems;
};
