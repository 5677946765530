import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			'&.Mui-focused, &:hover': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: palette.inputBorder
				}
			},

			'&.Mui-disabled': {
				opacity: 0.5,
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: palette.inputBorder,
					color: palette.mainTextColor
				}
			}
		},

		input: {
			color: palette.mainTextColor,
			WebkitBackgroundClip: 'text',
			WebkitTextFillColor: palette.mainTextColor,
			'&::placeholder': {
				color: palette.mainTextColor
			}
		},

		notchedOutline: {
			borderColor: palette.inputBorder
		},

		multiline: {
			color: palette.mainTextColor,
			background: 'transparent'
		}
	}
};
