import React, { ReactNode } from 'react';
import { SplideSlide } from '@splidejs/react-splide';
import { Link } from '@mui/material';

export interface ICarouselItem {
	children: ReactNode;
	className?: HTMLDivElement['className'];
	activeIndex?: number;
	itemIndex?: number;
}

export default function CarouselItem({
	children,
	activeIndex,
	itemIndex,
	...restProps
}: ICarouselItem) {
	return <SplideSlide {...restProps}>{children}</SplideSlide>;
}
