import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import gpt from '../../gptAPI';
import Prompts from '../../common';

export default function GenerateFilters(props) {
	const { company, setFilters, prompts, settings } = props;
	const [state, setState] = useState<any>({});
	const { resFilters, loading } = state;
	const handlerGenerate = () => {
		gpt.generateFilters(
			{
				description: company,
				prompt: prompts[Prompts.filter],
				model: settings.model
			},
			setState
		);
	};

	const handlerSave = () => {
		setFilters(resFilters);
	};

	return (
		<Box
			component='form'
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' }
			}}
			noValidate
			autoComplete='off'
		>
			<div>
				<LoadingButton
					variant='contained'
					onClick={() => handlerGenerate()}
					loading={loading}
					loadingPosition='end'
				>
					Generate filters
				</LoadingButton>
				<Button variant='outlined' onClick={() => handlerSave()}>
					Save filters
				</Button>
			</div>
			<div>
				{/* <div><pre>{JSON.stringify(resCategories, null, 2) }</pre></div> */}
				<pre>{JSON.stringify(resFilters, null, 2)}</pre>
			</div>
		</Box>
	);
}
