import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { LANGUAGES_CODES, LANGUAGES_LIST } from '_constants';
import LanguageDetector from 'i18next-browser-languagedetector';

const backendOptions = {
	loadPath:
		'https://sam-deployment-swiss-linker-localizations3bucket-zcduq6xfnwwe.s3.eu-central-1.amazonaws.com/builder/{{lng}}.json'
};

// Опции i18next
const i18nextOptions = {
	react: {
		useSuspense: true
	},
	backend: backendOptions,
	fallbackLng: LANGUAGES_CODES[0],
	languages: LANGUAGES_CODES,
	interpolation: {
		escapeValue: false // not needed for react!!
	},
	detection: {
		lookupFromPathIndex: 0,
		order: ['path', 'localStorage', 'navigator']
	}
};

i18n.use(LanguageDetector)
	.use(HttpApi)
	.use(initReactI18next)
	.init(i18nextOptions);

export default i18n;
