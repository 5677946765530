import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			lineHeight: '24px',
			fontWeight: 500,
			textTransform: 'capitalize' as const,
			boxShadow: 'none'
		},

		sizeSmall: {
			fontSize: 14,
			minHeight: 40,
			padding: '10px 15px'
		},

		sizeLarge: {
			fontSize: 20,
			padding: '16px 24px'
		},

		outlinedPrimary: {
			borderColor: palette.inputBorder,
			color: palette.mainTextColor
		},

		contained: {
			'&.Mui-disabled': {
				color: palette.mainTextColor,
				background: palette.secondaryGray
			}
		}
	},
	defaultProps: {}
};
