// API_URL = 'http://127.0.0.1:8000/api-public/integration'
import { map } from 'lodash';

export const API_URL = 'https://api-dev.zenit-auto.com/api-public/integration';

export const DEFAULT_PROMPTS = {
	DESCRIPTION: 'Generate a company description',
	OUR_MISSION: 'Generate an our mission',
	ABOUT_US: 'Generate an about us text',
	SLOGAN: 'Generate an our slogan',
	BENEFITS: 'Generate an our benefits',
	ACTIVITIES: `What type of activity is classified according to the
	 "NACE" or "NAICS" or "NOGA" or "SIC" classification standard?.
	  Select a classifier based on the country of the company.
	   If the answer is based on several classifiers, combine it into one answer.`,
	CATEGORIES:
		'Make me a detail tree of product categories. Without general category.',
	FILTERS: 'Make an examples filter attributes for real products.',
	PRODUCTS: 'Make a list of 3 examples of real products.',
	PRODUCT_CATEGORY_PROPERTY: 'Find a category and properties'
};

export const GPT_VERSIONS = {
	GPT3: 'gpt-3.5-turbo',
	GPT4: 'gpt-4',
	GPT4_TURBO: 'gpt-4-1106-preview'
};

export const PROMPT_TYPES_CODE = {
	DESCRIPTION: 'description',
	ABOUT_US: 'about_us',
	OUR_MISSION: 'our_mission',
	SLOGAN: 'slogan',
	BENEFITS: 'benefits',
	ACTIVITIES: 'activities',
	CATEGORIES: 'categories',
	PROPERTIES: 'properties',
	PRODUCTS: 'products'
};

export const METHODS_FILL_PRODUCTS = {
	GENERATE: 'generate',
	IMPORT: 'import'
};

export const DEFAULT_EDITOR_CONFIG = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'link',
			'|',
			'bulletedList',
			'numberedList',
			'blockquote',
			'|',
			'undo',
			'redo'
		]
	}
};
export const LANGUAGES_LIST = [
	{ label: 'English', value: 'en' },
	{ label: 'German', value: 'de' },
	{ label: 'French', value: 'fr' },
	{ label: 'Italian', value: 'it' }
];
export const LANGUAGES_CODES = map(LANGUAGES_LIST, (item) => item?.value);
