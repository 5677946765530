import palette from '../palette';

export default {
	styleOverrides: {
		label: {
			color: palette.mainTextColor,
			fontSize: 16
		}
	},
	defaultProps: {}
};
