export default {
	// html: {
	// 	height: '100%'
	// },

	body: {
		minHeight: '100vh',
		height: '100%',
		margin: 0,
		padding: 0,

		'& *': {
			boxSizing: 'border-box' as const
		}
	}
};
