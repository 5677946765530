import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
	filter,
	find,
	forEach,
	head,
	isEmpty,
	isFunction,
	map,
	size,
	sortBy
} from 'lodash';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BaseModalProps, IImageItem } from 'types';
import {
	IGenerateUploadUrlResponse,
	useGenerateUrlUploadMutation,
	useUploadImageMutation
} from 'store/services/ai.service';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Image from 'components/Image';
import { generateIdForEntity } from 'helpers';
import EditIcon from '@mui/icons-material/Edit';
import LoaderWithData from 'components/_layout/LoaderWithData';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

export interface IFormValuesEditProductsImg {
	images: IImageItem[];
	id: string;
}

export interface EditProductImgModalProps extends BaseModalProps {
	activeImages?: IImageItem[];
	hasSingleImage?: boolean;
	id: string;
	handleSave?: (values: IFormValuesEditProductsImg) => void;
	replaceImageQuery?: () => Promise<any>;
}

const FileInputStyled = styled('input')(({ theme }) => ({
	display: 'none',
	width: 0,
	height: 0,
	overflow: 'hidden'
}));

const StyledDeleteButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 0,
	top: 0,
	borderRadius: 0,
	color: theme.palette.error.main,
	backgroundColor: theme.palette.bodyColor,
	padding: 0,

	'&:hover': {
		backgroundColor: theme.palette.bodyColor
	}
}));

function EditProductImgModal({
	isOpen,
	handleClose,
	activeImages,
	id,
	hasSingleImage = false,
	handleSave,
	replaceImageQuery
}: EditProductImgModalProps) {
	const { t } = useTranslation();

	const [getUrl, { isLoading: isLoadingGetUrl }] =
		useGenerateUrlUploadMutation();
	const [uploadImage, { isLoading: isLoadingUploadImage }] =
		useUploadImageMutation();

	const inputFileRef = useRef(null);

	const [currentImage, setCurrentImage] = useState<IImageItem>(null);

	const [errorMessage, setErrorMessage] = useState(undefined);

	const [isReplacingImage, setIsReplacingImage] = useState(false);

	const generateIdImages = useMemo(
		() => map(activeImages, (item) => generateIdForEntity(item)),
		[activeImages]
	);

	const { control, handleSubmit, reset, setValue } = useForm({
		values: {
			images: generateIdImages,
			id: id
		}
	});

	const images = useWatch({ control, name: 'images' });

	useEffect(() => {
		if (isEmpty(generateIdImages)) return;

		const primaryImage = find(generateIdImages, (item) => item?.isPrimary);

		if (isEmpty(primaryImage) && isEmpty(head(generateIdImages))) return;

		setCurrentImage(primaryImage || head(generateIdImages));
	}, [generateIdImages]);

	useEffect(() => {
		if (!isOpen) setCurrentImage(null);
	}, [isOpen]);

	const handleSaveChanges = (data) => {
		if (isFunction(handleSave)) handleSave(data);

		setErrorMessage(undefined);
		handleClose();
		reset();
	};

	const handleResetChanges = () => {
		handleClose();
		setCurrentImage(null);
		setErrorMessage(undefined);
		reset();
	};

	const handleThumbnailClick = (image: IImageItem) => {
		setErrorMessage(undefined);
		setCurrentImage(image);
	};

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;

		setErrorMessage(undefined);

		if (files) {
			forEach(files, (fileItem) => {
				const maxSizeInBytes = 512000;

				if (fileItem.size > maxSizeInBytes) {
					setErrorMessage(t('file_size_limit'));
					return;
				}

				getUrl({}).then(
					(response: { data: IGenerateUploadUrlResponse }) => {
						const blob = new Blob([fileItem], {
							type: fileItem.type
						});

						uploadImage({
							url: response?.data?.uploadURL,
							file: blob
						}).then(() => {
							const imageUrl = new URL(response?.data?.uploadURL);
							const fullUrl = `${imageUrl.protocol}//${imageUrl.host}${imageUrl.pathname}`;

							const newImageItem = generateIdForEntity({
								src: fullUrl
							});

							setValue(
								'images',
								hasSingleImage
									? [newImageItem]
									: [...images, newImageItem]
							);

							setCurrentImage(newImageItem);
						});
					}
				);
			});
		}
	};

	const handleSelectFile = (e) => {
		e.preventDefault();
		inputFileRef.current.click();
	};

	const handleChangePrimaryImage = (idImage: string) => (event) => {
		setErrorMessage(undefined);

		const { checked } = event.target;

		const newImages = map(images, (item) =>
			idImage === item?.id
				? { ...item, isPrimary: checked }
				: { ...item, isPrimary: false }
		);

		const sortImages = sortBy(newImages, (item) => !item?.isPrimary);
		setCurrentImage((prevState) => ({ ...prevState, isPrimary: checked }));

		setValue('images', sortImages);
	};

	const handleDeleteImage = (idImage: string) => (event) => {
		event.stopPropagation();

		setErrorMessage(undefined);

		const newImages = filter(images, (item) => item?.id !== idImage);

		if (currentImage?.id === idImage) setCurrentImage(null);

		setValue('images', newImages);
	};

	const handleGenerateImage = (event) => {
		event.stopPropagation();

		setIsReplacingImage(true);

		replaceImageQuery()
			.then((response: any) => {
				if (!currentImage?.id) {
					const newImageItem = generateIdForEntity({
						src: response?.data?.image?.src
					});

					setValue(
						'images',
						hasSingleImage
							? [newImageItem]
							: [...images, newImageItem]
					);
					setCurrentImage(newImageItem);

					return;
				}

				const newImages = map(images, (item) =>
					item?.id === currentImage?.id
						? { ...item, src: response?.data?.image?.src }
						: item
				);

				const findImage = find(
					newImages,
					(item) => item?.id === currentImage?.id
				);
				setCurrentImage(findImage);

				setValue('images', newImages);
			})
			.finally(() => setIsReplacingImage(false));
	};

	return (
		<Dialog open={isOpen} fullWidth maxWidth='sm'>
			<DialogTitle sx={{ m: 0, p: 2 }}>{t('select_image')}</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={handleResetChanges}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
					color: (theme) => theme.palette.mainTextColor
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers sx={{ position: 'relative' }}>
				{isLoadingGetUrl || isLoadingUploadImage || isReplacingImage ? (
					<LoaderWithData />
				) : null}

				<Stack alignItems='center'>
					<Box mb={1} sx={{ width: '100%' }}>
						{currentImage ? (
							<Image
								src={currentImage.src}
								borderRadius={0}
								alt='Current product'
							/>
						) : (
							<Typography align='center' color='mainTextColor'>
								{t('choose_image')}
							</Typography>
						)}

						{currentImage && size(activeImages) > 1 ? (
							<Box mt={1}>
								<FormControlLabel
									control={
										<Checkbox
											onChange={handleChangePrimaryImage(
												currentImage?.id
											)}
											checked={Boolean(
												currentImage?.isPrimary
											)}
										/>
									}
									label={t('set_as_primary')}
								/>
							</Box>
						) : null}
					</Box>

					<Stack
						mb={2}
						direction='row'
						justifyContent='flex-end'
						sx={{ width: '100%' }}
					>
						<Box>
							<IconButton
								aria-label='replace'
								size='large'
								onClick={handleGenerateImage}
							>
								<ReplayIcon
									sx={{
										color: (theme) =>
											theme.palette.mainTextColor,
										fontSize: 40
									}}
								/>
							</IconButton>
						</Box>
					</Stack>

					<Stack
						direction='row'
						flexWrap='wrap'
						alignItems='center'
						gap={2}
						mb={3}
					>
						{!hasSingleImage &&
							map(images, (image, key) => (
								<Stack
									key={`${key}_${image?.id}`}
									direction='row'
									alignItems='center'
									sx={{
										width: 90,
										height: 90,
										position: 'relative'
									}}
									onClick={() => handleThumbnailClick(image)}
								>
									<StyledDeleteButton
										size='small'
										onClick={handleDeleteImage(image?.id)}
									>
										<CloseIcon />
									</StyledDeleteButton>

									<Image
										src={image.src}
										borderRadius={0}
										alt={`Thumbnail ${key}`}
									/>
								</Stack>
							))}

						<Stack
							component='label'
							alignItems='center'
							htmlFor='file'
						>
							<IconButton
								size='large'
								onClick={handleSelectFile}
								color='primary'
								sx={{ padding: '5px' }}
							>
								{hasSingleImage ? (
									<EditIcon sx={{ fontSize: 40 }} />
								) : (
									<AddIcon sx={{ fontSize: 40 }} />
								)}
							</IconButton>
						</Stack>

						{hasSingleImage && size(activeImages) > 0 ? (
							<IconButton
								size='large'
								onClick={handleDeleteImage(currentImage?.id)}
								color='primary'
								sx={{
									padding: '5px',
									color: (theme) => theme.palette.error.main
								}}
							>
								<CloseIcon sx={{ fontSize: 40 }} />
							</IconButton>
						) : null}
					</Stack>
				</Stack>

				{errorMessage && (
					<Box>
						<Alert severity='error' variant='filled'>
							{errorMessage}
						</Alert>
					</Box>
				)}

				<FileInputStyled
					id='file'
					name='file'
					type='file'
					ref={inputFileRef}
					onChange={handleImageUpload}
					accept='image/*'
				/>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					size='large'
					onClick={handleSubmit(handleSaveChanges)}
					fullWidth
				>
					{t('save_and_continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default React.memo(EditProductImgModal);
