import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import LoadIndicator from 'components/_layout/LoadIndicator';

const LoaderBox = styled(Box)(() => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: 999,
	background: 'rgba(0, 0, 0, 0.6)',
	left: 0,
	top: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}));

export default function LoaderWithData() {
	return (
		<LoaderBox>
			<LoadIndicator />
		</LoaderBox>
	);
}
