import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';


export default function Filters(props) {
    const {filters} = props
    return (
        <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            {filters.map(d => (<div>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt={d.name} src="/static/images/avatar/1.jpg"/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={d.name}
                        secondary={
                            <React.Fragment>
                                {d.values.join(', ')}
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li"/>
            </div>))}

        </List>
    );
}