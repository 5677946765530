import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Table from 'components/_layout/Table';
import { IHeaderColumn } from 'components/_layout/Table/components/TableHeader';
import { map, isEmpty, forEach, head } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store';
import {
	activeLangSelector,
	categoriesRawSelector,
	categoriesSelector,
	formValuesSelector,
	gptVersionSelector,
	isTestModeSelector,
	removeCategory,
	updateCategory
} from 'store/slices/masterFormSlice';
import {
	useGenerateCategoriesMutation,
	useLazyGenerateImageDalleQuery,
	useLazyGenerateImagePixelQuery,
	useReplaceCategoryMutation
} from 'store/services/ai.service';
import LoadIndicator from 'components/_layout/LoadIndicator';
import LoaderWithData from 'components/_layout/LoaderWithData';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditCategoryModal from 'components/_modals/EditCategoryModal';
import useToggle from 'hooks/useToggle';
import { IFormValuesEditCategories } from 'components/_modals/EditCategoryModal/EditCategoryModal';
import EditPrompt from 'components/EditPrompt';
import { DEFAULT_PROMPTS, PROMPT_TYPES_CODE } from '_constants';
import CategoryItem from 'components/CategoryItem';
import usePrompts from 'hooks/usePrompts';
import { generateIdForEntity } from 'helpers';
import { ICategory } from 'types';
import EditProductImgModal, {
	IFormValuesEditProductsImg
} from 'components/_modals/EditProductImgModal/EditProductImgModal';
import { useTranslation } from 'react-i18next';

export default function CategoriesSection() {
	const { t } = useTranslation();

	const categories = useAppSelector(categoriesSelector);
	const rawCategories = useAppSelector(categoriesRawSelector);
	const formValues = useAppSelector(formValuesSelector);

	const gptVersion = useAppSelector(gptVersionSelector);
	const isTestMode = useAppSelector(isTestModeSelector);
	const activeLang = useAppSelector(activeLangSelector);

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const dispatch = useAppDispatch();

	const [editedCategory, setEditedCategory] = useState(null);

	const {
		open: isOpenCategoryModal,
		handleOpen: handleOpenCategoryModal,
		handleClose: handleCloseCategoryModal
	} = useToggle();

	const [generateCategories, { isLoading }] = useGenerateCategoriesMutation();
	const [replaceCategory] = useReplaceCategoryMutation();

	const [generateImagePixel] = useLazyGenerateImagePixelQuery();
	const [generateImageDalle] = useLazyGenerateImageDalleQuery();

	const [loadingImages, setLoadingImages] = useState({});
	const [updatingCategories, setUpdatingCategories] = useState({});

	const { promptsList, lastPrompt, createPrompt, isLoadingPromptsList } =
		usePrompts({
			typeCode: PROMPT_TYPES_CODE.CATEGORIES
		});

	const handleGenerateImagesRecursive = (
		categoryItem: ICategory,
		parentName: string
	) => {
		if (!categoryItem?.id) return;

		setLoadingImages((prevState) => ({
			...prevState,
			[categoryItem?.id]: true
		}));

		const generateFunction = isTestMode
			? generateImagePixel
			: generateImageDalle;

		generateFunction({
			name: `${parentName} ${categoryItem?.name}`
		})
			.then((response) => {
				dispatch(
					updateCategory({
						id: categoryItem?.id,
						image: response?.data?.image
					})
				);
			})
			.finally(() =>
				setLoadingImages((prevState) => ({
					...prevState,
					[categoryItem?.id]: false
				}))
			);

		if (isEmpty(categoryItem?.sub_categories)) return;

		forEach(categoryItem?.sub_categories, (item) =>
			handleGenerateImagesRecursive(item, categoryItem?.name)
		);
	};

	const handleGenerateCategories = () => {
		generateCategories({
			description: `for specifications ${JSON.stringify(
				formValues?.companyActivities
			)}`,
			activeLang: activeLang,
			prompt:
				formValues.promptCategories ||
				lastPrompt?.prompt ||
				DEFAULT_PROMPTS.CATEGORIES,
			model: gptVersion
		}).then(({ data }: { data: ICategory[] }) => {
			forEach(data, (item) => handleGenerateImagesRecursive(item, ''));
		});
	};

	const {
		open: isOpenProductImgModal,
		handleOpen: handleOpenProductImgModal,
		handleClose: handleCloseProductImgModal
	} = useToggle();

	useEffect(() => {
		if (!isEmpty(categories) || isLoadingPromptsList) return;

		handleGenerateCategories();
	}, [isLoadingPromptsList]);

	const headerConfig: IHeaderColumn[] = useMemo(
		() => [
			{
				columnSizeDefault: 4,
				label: t('table_name'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 6,
				label: t('table_description'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 2,
				label: '',
				justifyStack: 'right'
			}
		],
		[]
	);

	const handleEditCategory = (id?: string) => () => {
		let findItem = null;

		const searchRecursiveCategory = (category) => {
			const tryFindItem = category?.id === id;

			if (tryFindItem) {
				findItem = category;
				return;
			}

			if (
				category?.sub_categories &&
				!isEmpty(category?.sub_categories)
			) {
				forEach(category.sub_categories, (item) =>
					searchRecursiveCategory(item)
				);
			}
		};

		forEach(categories, (item) => searchRecursiveCategory(item));

		setEditedCategory(findItem);
		handleOpenCategoryModal();
	};

	const handleSaveEdit = (data: IFormValuesEditCategories) => {
		const categoryItem = generateIdForEntity({
			description: data?.categoryDescription,
			name: data?.categoryName,
			id: data?.id,
			parentId: data?.parentCategory?.value || ''
		});

		dispatch(updateCategory(categoryItem));
	};

	const handleRemoveCategory = (id: string) => {
		if (!id) return;

		dispatch(removeCategory(id));
	};

	const handleEditProductImg = (id?: string) => () => {
		setEditedCategory(rawCategories?.[id]);
		handleOpenProductImgModal();
	};

	const replaceCategoryItem = (id: string) => () => {
		if (!id) return;

		const categoryItem = rawCategories?.[id];
		const parentCategory = rawCategories?.[categoryItem?.parentId];

		if (!categoryItem) return;

		setUpdatingCategories((prevState) => ({ ...prevState, [id]: true }));

		replaceCategory({
			model: gptVersion,
			id: categoryItem?.id,
			activeLang: activeLang,
			description: categoryItem?.description,
			name: categoryItem?.name
		}).finally(() =>
			setUpdatingCategories((prevState) => ({
				...prevState,
				[id]: false
			}))
		);

		setLoadingImages((prevState) => ({ ...prevState, [id]: true }));

		const generateFunction = isTestMode
			? generateImagePixel
			: generateImageDalle;

		generateFunction({
			name: `${parentCategory?.name ? `${parentCategory?.name}` : ''} ${
				categoryItem?.name
			}`
		})
			.then((response) => {
				dispatch(
					updateCategory({ id: id, image: response?.data?.image })
				);
			})
			.finally(() => {
				setLoadingImages((prevState) => ({
					...prevState,
					[id]: false
				}));
			});
	};

	const handleSaveImageCategory = (data: IFormValuesEditProductsImg) => {
		dispatch(
			updateCategory({
				id: data?.id,
				image: head(data?.images) || {}
			})
		);
	};

	const renderRecursiveCategories = useCallback(
		(category: ICategory, level = 1) => (
			<React.Fragment>
				<CategoryItem
					level={level}
					handleEditCategory={handleEditCategory}
					handleRemoveCategory={handleRemoveCategory}
					handleReplaceCategory={replaceCategoryItem}
					name={category?.name}
					isLoadingImage={loadingImages?.[category?.id]}
					handleEditImage={handleEditProductImg}
					description={category?.description}
					id={category?.id}
					image={category?.image?.src}
					isUpdatingItem={updatingCategories?.[category?.id]}
				/>
				{category?.sub_categories && !isEmpty(category?.sub_categories)
					? map(category?.sub_categories, (subCategory) => (
							<React.Fragment key={subCategory?.name}>
								{renderRecursiveCategories(
									subCategory,
									level + 1
								)}
							</React.Fragment>
					  ))
					: null}
			</React.Fragment>
		),
		[categories, loadingImages, updatingCategories]
	);

	const generateNewImage = () => {
		if (!editedCategory?.id) return;

		const categoryItem = rawCategories?.[editedCategory?.id];
		const parentCategory = rawCategories?.[categoryItem?.parentId];

		const generateFunction = isTestMode
			? generateImagePixel
			: generateImageDalle;

		return generateFunction({
			name: `${parentCategory ? `${parentCategory?.name}.` : ''} ${
				categoryItem?.name
			}`
		});
	};

	return (
		<Box>
			<Box mb={3}>
				{!isLoadingPromptsList && (
					<EditPrompt
						name='promptCategories'
						callbackSavePrompt={createPrompt}
						promptsList={promptsList}
						externalValue={
							formValues.promptCategories ||
							lastPrompt?.prompt ||
							DEFAULT_PROMPTS.CATEGORIES
						}
						defaultValue={
							lastPrompt?.prompt || DEFAULT_PROMPTS.CATEGORIES
						}
					/>
				)}
			</Box>

			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Typography variant='h1' component='h2' color='mainTextColor'>
				{t('categories_section_title')}
			</Typography>

			<Typography
				mt={1}
				variant='h2'
				component='h3'
				gutterBottom
				color='primaryGray'
			>
				{t('categories_section_description')}
			</Typography>

			<Box mt={5} position='relative'>
				{!isEmpty(categories) && isLoading ? <LoaderWithData /> : null}

				{isEmpty(categories) && isLoading ? (
					<LoadIndicator />
				) : (
					<Box sx={{ overflowX: 'auto' }}>
						<Box sx={{ minWidth: 700 }}>
							<Table>
								<Table.Header
									spacingBetweenColumn={3}
									columns={headerConfig}
								/>

								{map(categories, (category, key) => (
									<React.Fragment key={category?.name || key}>
										{renderRecursiveCategories(category)}
									</React.Fragment>
								))}
							</Table>
						</Box>
					</Box>
				)}

				<Box
					sx={{
						position: 'absolute',
						top: 1,
						right: 0,
						width: 45
					}}
				>
					<IconButton
						sx={{
							color: (theme) => theme.palette.mainTextColor,
							border: 1,
							borderRadius: 1,
							backgroundColor: (theme) => theme.palette.bodyColor,
							width: 45,
							height: 45
						}}
						onClick={handleEditCategory()}
						size='large'
					>
						<AddIcon />
					</IconButton>
				</Box>
			</Box>

			<Stack mt={10} mb={2} direction='row' spacing={5}>
				<Button
					fullWidth
					onClick={handleGenerateCategories}
					disabled={isLoading}
					variant='contained'
					color='secondary'
					size='large'
				>
					{t('try_again')}
				</Button>

				<Button
					fullWidth
					onClick={handleNextStep}
					disabled={isLoading}
					variant='contained'
					color='primary'
					size='large'
				>
					{t('save_and_continue')}
				</Button>
			</Stack>

			<EditProductImgModal
				isOpen={isOpenProductImgModal}
				activeImages={[editedCategory?.image] || []}
				id={editedCategory?.id}
				hasSingleImage
				handleSave={handleSaveImageCategory}
				handleClose={handleCloseProductImgModal}
				replaceImageQuery={generateNewImage}
			/>

			<EditCategoryModal
				activeCategory={editedCategory}
				isOpen={isOpenCategoryModal}
				handleSave={handleSaveEdit}
				handleClose={handleCloseCategoryModal}
			/>
		</Box>
	);
}
