import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import LoadIndicator from 'components/_layout/LoadIndicator';

export interface ImageProps {
	width?: number;
	height?: number;
	objectFit?: string;
	src: string;
	alt: string;
	borderRadius?: number | string;
}

export default function Image({
	alt,
	width,
	height,
	src,
	borderRadius = 5,
	objectFit = 'contain'
}: ImageProps) {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);

	const handleImageLoaded = () => {
		setIsLoading(false);
		setError(false);
	};

	const handleImageError = () => {
		setIsLoading(false);
		setError(true);
	};

	return (
		<React.Fragment>
			{isLoading && (
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='center'
					sx={{ width: width || '100%', height: height || 'auto' }}
				>
					<LoadIndicator />
				</Stack>
			)}

			{error && (
				<Stack
					direction='row'
					justifyContent='center'
					sx={{ width: width || '100%', height: height || 'auto' }}
				>
					<ImageOutlinedIcon
						sx={{
							color: (theme) => theme.palette.mainTextColor,
							width: width || '100%',
							height: height || 'auto'
						}}
					/>
				</Stack>
			)}

			<Box
				component='img'
				alt={alt}
				width={width}
				height={height}
				src={src}
				onLoad={handleImageLoaded}
				onError={handleImageError}
				sx={{
					display: !error && !isLoading ? 'block' : 'none',
					maxWidth: '100%',
					width: width || '100%',
					height: height || 'auto',
					maxHeight: '100%',
					borderRadius: borderRadius,
					objectFit: objectFit
				}}
			/>
		</React.Fragment>
	);
}
