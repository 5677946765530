import palette from 'theming/palette';

export default {
	styleOverrides: {
		root: {
			'& svg': {
				color: palette.inputBorder
			}
		}
	}
};
