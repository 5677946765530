export default {
	fontFamily: ['Oxanium', 'sans-serif'].join(', '),

	h1: {
		fontWeight: 800,
		fontSize: 64
	},
	h2: {
		fontWeight: 500,
		fontSize: 24
	},
	h3: {
		fontWeight: 400,
		fontSize: 24
	},
	h4: {
		fontWeight: 600,
		fontSize: 20
	},
	h5: {
		fontWeight: 400,
		fontSize: 20
	},
	h6: {
		fontWeight: 400,
		fontSize: 14
	},
	body1: {
		fontSize: 16,
		fontWeight: 400
	},
	body2: {
		fontSize: 16,
		fontWeight: 700
	},
	subtitle1: {
		fontWeight: 600,
		fontSize: 24
	},
	subtitle2: {
		fontWeight: 400,
		fontSize: 24
	},
	subtitle3: {
		fontWeight: 400,
		fontSize: 20
	},
	// button: {
	//     textTransform: 'none' as const,
	//     fontSize: 14,
	//     fontWeight: 500,
	// },
	caption: {
		fontSize: 12,
		fontWeight: 600
	},
	caption2: {
		fontSize: 12,
		fontWeight: 400
	},
	overline: {
		fontSize: 12,
		fontWeight: 400
	}
};
