import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, IconButton, Stack } from '@mui/material';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store';
import { formValuesSelector, setFormState } from 'store/slices/masterFormSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditPrompt from 'components/EditPrompt';
import { DEFAULT_PROMPTS, PROMPT_TYPES_CODE } from '_constants';
import usePrompts from 'hooks/usePrompts';
import GenerateText from 'components/GenerateText';
import { useTranslation } from 'react-i18next';

function DescriptionSection() {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const formValues = useAppSelector(formValuesSelector);

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const { control, setValue } = useFormContext();
	const valueDescription = useWatch({ name: 'companyDescription' });
	const valueMission = useWatch({ name: 'companyMission' });
	const valueSlogan = useWatch({ name: 'companySlogan' });
	const valueBenefits = useWatch({ name: 'companyBenefits' });
	const valueAboutUs = useWatch({ name: 'companyAboutUs' });

	const [isLoadingDescription, setIsLoadingDescription] = useState(false);
	const [isLoadingAboutUs, setIsLoadingAboutUs] = useState(false);
	const [isLoadingMission, setIsLoadingMission] = useState(false);
	const [isLoadingSlogan, setIsLoadingSlogan] = useState(false);
	const [isLoadingBenefits, setIsLoadingBenefits] = useState(false);

	const { promptsList, lastPrompt, isLoadingPromptsList, createPrompt } =
		usePrompts({
			typeCode: PROMPT_TYPES_CODE.DESCRIPTION
		});

	useEffect(() => {
		dispatch(
			setFormState({
				companyDescription: valueDescription,
				companyMission: valueMission,
				companySlogan: valueSlogan,
				companyBenefits: valueBenefits,
				companyAboutUs: valueAboutUs
			})
		);
	}, [
		valueDescription,
		valueMission,
		valueSlogan,
		valueBenefits,
		valueAboutUs
	]);

	const requestValues = {
		companyName: formValues?.companyInfo,
		companyUrl: formValues?.companyUrl,
		classificationDescription: formValues?.companyActivities
	};

	return (
		<>
			<Box mb={3}>
				{!isLoadingPromptsList && (
					<EditPrompt
						name='promptDescription'
						callbackSavePrompt={createPrompt}
						promptsList={promptsList}
						externalValue={
							formValues.promptDescription ||
							lastPrompt?.prompt ||
							DEFAULT_PROMPTS.DESCRIPTION
						}
						defaultValue={
							lastPrompt?.prompt || DEFAULT_PROMPTS.DESCRIPTION
						}
					/>
				)}
			</Box>

			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant='h1'
						component='h2'
						color='mainTextColor'
					>
						{t('description_section_title')}
					</Typography>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						mt={1}
						variant='h2'
						component='h3'
						gutterBottom
						color='primaryGray'
					>
						{t('we_have_create_description')}
					</Typography>
					<Typography
						variant='h2'
						component='h3'
						gutterBottom
						color='primaryGray'
					>
						{t('how_do_you_like')}
					</Typography>
				</Grid>
			</Grid>

			<Box mt={6}>
				<GenerateText
					name='companyDescription'
					title={t('company_description')}
					control={control}
					typePrompt={PROMPT_TYPES_CODE.DESCRIPTION}
					formValue={formValues?.companyDescription}
					setValue={setValue}
					externalPrompt={formValues.promptDescription}
					requestValues={requestValues}
					setLoading={setIsLoadingDescription}
				/>
			</Box>

			<Box mt={3}>
				<GenerateText
					name='companyAboutUs'
					title={t('company_about_us')}
					control={control}
					typePrompt={PROMPT_TYPES_CODE.ABOUT_US}
					formValue={formValues?.companyAboutUs}
					setValue={setValue}
					requestValues={requestValues}
					setLoading={setIsLoadingAboutUs}
				/>
			</Box>

			<Box mt={3}>
				<GenerateText
					name='companyMission'
					title={t('company_our_mission')}
					control={control}
					typePrompt={PROMPT_TYPES_CODE.OUR_MISSION}
					formValue={formValues?.companyMission}
					setValue={setValue}
					requestValues={requestValues}
					setLoading={setIsLoadingMission}
				/>
			</Box>

			<Box mt={3}>
				<GenerateText
					name='companySlogan'
					title={t('company_our_slogan')}
					control={control}
					typePrompt={PROMPT_TYPES_CODE.SLOGAN}
					formValue={formValues?.companySlogan}
					setValue={setValue}
					requestValues={requestValues}
					setLoading={setIsLoadingSlogan}
				/>
			</Box>

			<Box mt={3}>
				<GenerateText
					name='companyBenefits'
					title={t('company_our_benefits')}
					control={control}
					typePrompt={PROMPT_TYPES_CODE.BENEFITS}
					formValue={formValues?.companyBenefits}
					setValue={setValue}
					requestValues={requestValues}
					setLoading={setIsLoadingBenefits}
				/>
			</Box>

			<Box mt={3.5} mb={2}>
				<Stack direction={{ md: 'row', xs: 'column' }} spacing={4}>
					<Button
						onClick={handleNextStep}
						variant='contained'
						size='large'
						color='primary'
						fullWidth
						disabled={
							isLoadingBenefits ||
							isLoadingSlogan ||
							isLoadingDescription ||
							isLoadingMission ||
							isLoadingAboutUs
						}
					>
						{t('save_and_continue')}
					</Button>
				</Stack>
			</Box>
		</>
	);
}

export default React.memo(DescriptionSection);
