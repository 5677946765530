import React, {
	ReactElement,
	ReactNode,
	useEffect,
	useRef,
	useState
} from 'react';
import '@splidejs/react-splide/css';
import { Splide, SplideProps, SplideTrack } from '@splidejs/react-splide';
import { Box } from '@mui/material';

import { map } from 'lodash';
import { styled } from '@mui/material/styles';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CarouselItem, { ICarouselItem } from './components/CarouselItem';

const MainSlider = styled(Splide)(({ theme }) => ({
	padding: theme.spacing(0, 7),

	'& .splide__slide > div': {
		borderRadius: 4,
		padding: theme.spacing(2, 1.5),
		margin: theme.spacing(0, 1),
		border: `1px solid ${theme.palette.inputBorder}`,
		height: '100%'
	},

	'& img': {
		maxHeight: 500
	}
}));

const ArrowSlider = styled('button')(({ theme }) => ({
	'&.splide__arrow': {
		backgroundColor: theme.palette.primary.main,
		height: 40,
		width: 40,
		opacity: 1,
		color: theme.palette.mainTextColor,
		borderRadius: '50%',

		'&:disabled': {
			opacity: 0.6,
			backgroundColor: theme.palette.secondaryGray
		},

		'& svg': {
			fill: theme.palette.mainTextColor
		}
	},

	'&.splide__arrow--next': {
		right: 0
	},

	'&.splide__arrow--prev': {
		left: 0
	}
}));

export interface CarouselProps extends SplideProps {
	children: ReactNode;
	perPage?: number;
	perMove?: number;
	id: string;
}

interface ICarouselCompound {
	Item?: ICarouselItem;
}

function Carousel({
	children,
	id,
	perPage = 1,
	perMove = 1
}: CarouselProps): ReactElement<CarouselProps> & ICarouselCompound {
	const mainRef = useRef<Splide>(null);
	const thumbsRef = useRef<Splide>(null);

	const [activeIndex, setActiveIndex] = useState<number | null>(null);

	const handleOnActive = (ref: any, slide: any) => {
		setActiveIndex(slide.slideIndex);
	};

	useEffect(() => {
		if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
			mainRef.current.sync(thumbsRef.current.splide);
		}
	}, []);

	return (
		<Box>
			<MainSlider
				id={id}
				ref={mainRef}
				aria-labelledby={id}
				options={{
					perPage: perPage,
					perMove: perMove,
					pagination: false
					// arrows: false,
					// isNavigation: true
				}}
				hasTrack={false}
				onActive={handleOnActive}
			>
				<SplideTrack>
					{Array.isArray(children)
						? map(children, (child, key) => (
								<React.Fragment key={key}>
									{React.cloneElement(child, {
										activeIndex,
										itemIndex: key
									})}
								</React.Fragment>
						  ))
						: children}
				</SplideTrack>

				<div className='splide__arrows'>
					<ArrowSlider className='splide__arrow splide__arrow--prev'>
						<NavigateNextIcon
							sx={{
								color: (theme) => theme.palette.mainTextColor
							}}
						/>
					</ArrowSlider>

					<ArrowSlider className='splide__arrow splide__arrow--next'>
						<NavigateNextIcon
							sx={{
								color: (theme) => theme.palette.mainTextColor
							}}
						/>
					</ArrowSlider>
				</div>
			</MainSlider>
		</Box>
	);
}

Carousel.Item = CarouselItem;

export default Carousel;
