import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default function useMasterFormValidation() {
	const { t } = useTranslation();

	return Yup.object().shape({
		companyInfo: Yup.string().trim().required(t('required_field')),
		companyUrl: Yup.string().trim().required(t('required_field')),

		companyCountry: Yup.object({
			value: Yup.string(),
			label: Yup.string()
		}).required(t('required_field')),

		companyLanguages: Yup.array()
			.of(
				Yup.object({
					value: Yup.string().required(),
					label: Yup.string().required()
				})
			)
			.required(t('required_field'))
			.min(1, t('required_field')),

		companyCurrencies: Yup.array()
			.of(
				Yup.object({
					value: Yup.string().required(),
					label: Yup.string().required()
				})
			)
			.required(t('required_field'))
			.min(1, t('required_field')),

		companyPrimaryLanguage: Yup.object({
			value: Yup.string(),
			label: Yup.string()
		}).required(t('required_field')),

		companyPrimaryCurrency: Yup.object({
			value: Yup.string(),
			label: Yup.string()
		}).required(t('required_field'))
	});
}
