import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { IconButton } from '@mui/material';
import LoupeIcon from '@mui/icons-material/Loupe';

export default function Products({ products, getProductDetails }) {
	return (
		<List
			sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
		>
			{products.map((d) => (
				<div>
					<ListItem
						alignItems='flex-start'
						secondaryAction={
							<IconButton aria-label='details'>
								<LoupeIcon
									onClick={() => getProductDetails(d)}
								/>
							</IconButton>
						}
					>
						<ListItemAvatar>
							<Avatar
								alt={d.name}
								src='/static/images/avatar/1.jpg'
							/>
						</ListItemAvatar>
						<ListItemText
							primary={d.name}
							secondary={
								<React.Fragment>{d.description}</React.Fragment>
							}
						/>
					</ListItem>
					<Divider variant='inset' component='li' />
				</div>
			))}
		</List>
	);
}
