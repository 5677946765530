import { styled } from '@mui/material/styles';
import { FormControlLabel, FormControlLabelProps } from '@mui/material';

export interface StyledRadioControlGroupProps extends FormControlLabelProps {
	isActive?: boolean;
}

export const StyledRadioControlGroup = styled(FormControlLabel, {
	shouldForwardProp: (propName: PropertyKey) => propName !== 'isActive'
})<StyledRadioControlGroupProps>(({ theme, isActive }) => ({
	marginLeft: 0,
	backgroundColor: isActive
		? theme.palette.bodyColor
		: theme.palette.primaryColor,
	padding: theme.spacing(4, 2),
	borderRadius: 4,
	marginBottom: theme.spacing(3),
	border: `2px solid ${theme.palette.primaryColor}`,

	'&:last-child': {
		marginBottom: 0
	}
}));
