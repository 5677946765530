class PromptsClass {
	category = 'category';

	filter = 'filter';

	products = 'products';

	product = 'product';

	getInit = () => ({
		category:
			'Make me a detail tree of product categories. Without general category.',
		filter: 'Make an examples filter attributes for real products.',
		products: 'Make a list of 20 examples of real products.',
		product: 'Make a description for the product in the format described.'
	});
}

const Prompts = new PromptsClass();
export default Prompts;
