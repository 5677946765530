import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CompanyInfo from 'components/CompanyInfo';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import BgCompanyIfo from 'images/img/companyInfoBg.png';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormContext } from 'react-hook-form';
import { setFormState } from 'store/slices/masterFormSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const BgImageCompanyInfo = styled(Box)(({ theme }) => ({
	'&:before': {
		content: `" "`,
		display: 'block',
		position: 'absolute',
		bottom: 0,
		zIndex: 2,
		right: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url('${BgCompanyIfo}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom right',
		backgroundSize: 'cover'
	}
}));

export default function CompanyInfoSection() {
	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const { t } = useTranslation();

	const { formState } = useFormContext();

	const dispatch = useDispatch();

	const handleChangeValues = (values) => {
		dispatch(setFormState(values));
	};

	const handleContinue = () => {
		handleNextStep();
	};

	return (
		<div>
			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant='h1'
						color='mainTextColor'
						component='h2'
					>
						{t('company_info')}
					</Typography>
				</Grid>
			</Grid>

			<Box mt={6}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={7}>
						<CompanyInfo handleChangeValues={handleChangeValues} />
					</Grid>
				</Grid>
			</Box>

			<Box mt={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={7}>
						<Grid container spacing={4}>
							<Grid item md={12} xs={12}>
								<Button
									variant='contained'
									color='primary'
									size='large'
									fullWidth
									disabled={!formState?.isValid}
									onClick={handleContinue}
								>
									{t('create_description')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
