import palette from 'theming/palette';

export default {
	styleOverrides: {
		root: {},

		colorPrimary: {
			'& svg': {
				color: palette.primaryGray
			},

			'&.Mui-checked': {
				'& svg': {
					color: palette.primary.main
				}
			}
		}
	}
};
