import React, { ReactNode } from 'react';
import { Grid, GridProps, RegularBreakpoints } from '@mui/material';

export interface ITableColumn {
    columnSizeDefault: number | 'auto' | boolean;
    responsiveColumnSizes?: RegularBreakpoints;
    sxColumn?: GridProps['sx'];
    children: ReactNode;
}

export default function TableColumn({ columnSizeDefault = 12, responsiveColumnSizes, sxColumn, children }: ITableColumn) {
    return (
        <Grid item sx={sxColumn} xs={columnSizeDefault} {...responsiveColumnSizes}>
            {children}
        </Grid>
    );
}
