import React, {
	createContext,
	ReactNode,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { toNumber, toString } from 'lodash';

export interface IStepperContext {
	activeStep?: number;
	handleChangeStep?: (newStep: number) => void;
	handleNextStep?: () => void;
	handlePrevStep?: () => void;
	handleResetSteps?: () => void;
	completedSteps?: Record<number, boolean>;
	nextStep?: number;
}

export const StepperContext = createContext<IStepperContext>({
	activeStep: undefined,
	handleChangeStep: undefined
});

interface IStepperProvider {
	children: ReactNode;
}

export default function StepperProvider({ children }: IStepperProvider) {
	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState({});

	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const stepUrl = searchParams.get('step');

		const newSearchParams = new URLSearchParams(searchParams);

		if (stepUrl !== toString(activeStep + 1)) {
			newSearchParams.set('step', toString(activeStep + 1));
			setSearchParams(newSearchParams);
		}
	}, [activeStep]);

	useEffect(() => {
		const stepUrl = searchParams.get('step');

		if (stepUrl) {
			setActiveStep(toNumber(stepUrl) - 1);

			const newCompleted = {};
			for (let i = 0; i <= toNumber(stepUrl) - 2; i++) {
				newCompleted[i] = true;
			}

			setCompleted(newCompleted);
		}
	}, []);

	const handleNext = () => {
		setCompleted((prevState) => ({ ...prevState, [activeStep]: true }));
		setActiveStep((prevStep) => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const contextValues = useMemo(
		() => ({
			activeStep,
			handleChangeStep: setActiveStep,
			handleNextStep: handleNext,
			handlePrevStep: handleBack,
			handleResetSteps: handleReset,
			completedSteps: completed,
			nextStep: activeStep + 1
		}),
		[activeStep, completed]
	);

	return (
		<StepperContext.Provider value={contextValues}>
			{children}
		</StepperContext.Provider>
	);
}
