import React, { useMemo } from 'react';
import { BaseModalProps, ICategory } from 'types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { find, isFunction } from 'lodash';
import { TOption } from 'components/_forms/AutocompleteField/AutocompleteField';
import { StyledCKEditorWrapper } from 'styled/StyledCKEditorWrapper/StyledCKEditorWrapper';
import { DEFAULT_EDITOR_CONFIG } from '_constants';
import CKEditor from '@ckeditor/ckeditor5-react';
import Editor from '@juniyadi/ckeditor5-custom-build';
import { useTranslation } from 'react-i18next';
import { parentCategoriesOptionsSelector } from '../../../store/slices/masterFormSlice';
import { useAppSelector } from '../../../store';
import AutocompleteField from '../../_forms/AutocompleteField';

export interface IFormValuesEditCategories {
	categoryName: string;
	categoryDescription: string;
	parentCategory?: TOption;
	id: string;
}

export interface EditCategoryModalProps extends BaseModalProps {
	activeCategory?: ICategory;
	handleSave: (values: IFormValuesEditCategories) => void;
}

function EditCategoryModal({
	isOpen,
	handleClose,
	activeCategory,
	handleSave
}: EditCategoryModalProps) {
	const { t } = useTranslation();

	const parentCategoriesOptions = useAppSelector(
		parentCategoriesOptionsSelector
	);

	const activeParentCategoryDefault = useMemo(
		() =>
			find(
				parentCategoriesOptions,
				(item) => item?.value === activeCategory?.parentId
			),
		[activeCategory]
	);

	const { control, handleSubmit, reset } = useForm({
		values: {
			categoryName: activeCategory?.name || '',
			categoryDescription: activeCategory?.description || '',
			id: activeCategory?.id || '',
			parentCategory: activeParentCategoryDefault || null
		}
	});

	const handleSaveChanges = (data: IFormValuesEditCategories) => {
		if (isFunction(handleSave)) handleSave(data);

		handleClose();
	};

	const handleResetChanges = () => {
		handleClose();
		reset();
	};

	return (
		<Dialog open={isOpen} fullWidth maxWidth='sm'>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{' '}
				{activeCategory?.id ? t('edit') : t('create')} {t('category')}
			</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={handleResetChanges}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
					color: (theme) => theme.palette.mainTextColor
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box
					sx={{
						// width: 500,
						maxWidth: '100%'
					}}
				>
					<Stack spacing={3}>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									label={t('name')}
									variant='outlined'
								/>
							)}
							name='categoryName'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<AutocompleteField
									handleChange={field.onChange}
									handleBlur={field.onBlur}
									disabled={field.disabled}
									value={field.value}
									name={field.name}
									options={parentCategoriesOptions}
									customIsOptionEqualToValue={(
										option,
										currentOption
									) =>
										option &&
										currentOption &&
										option?.value?.id ===
											currentOption?.value?.id
									}
									label={t('parent_category')}
									textFieldProps={{ fullWidth: true }}
								/>
							)}
							name='parentCategory'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<StyledCKEditorWrapper>
									<CKEditor
										editor={Editor}
										config={DEFAULT_EDITOR_CONFIG}
										data={field.value}
										onChange={(event, editor) =>
											field.onChange(editor.getData())
										}
									/>
								</StyledCKEditorWrapper>
							)}
							name='categoryDescription'
							control={control}
						/>
					</Stack>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					size='large'
					onClick={handleSubmit(handleSaveChanges)}
					fullWidth
				>
					{t('save_and_continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default React.memo(EditCategoryModal);
