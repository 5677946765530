import React, { ReactNode } from 'react';
import { Grid, GridProps, Paper, PaperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyledPaper extends PaperProps {
	level?: number;
}

export const StyledPaper = styled(Paper, {
	shouldForwardProp: (prop) => prop !== 'level'
})<IStyledPaper>(({ level, theme }) => ({
	transition: '0.3s',
	// paddingLeft: theme.spacing(2 * level),
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(2),
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	borderRadius: 0,
	borderBottom: `1px solid ${theme.palette.inputBorder}`,
	color: theme.palette.mainTextColor
	// ...(withHover && {
	// 	'&:hover': {
	// 		background: theme.palette.secondaryHover
	// 	}
	// })
}));

export interface ITableRow {
	countColumns?: number;
	children?: ReactNode;
	sxRow?: GridProps['sx'];
	spacingBetweenColumns?: GridProps['spacing'];
	direction?: GridProps['direction'];
	alignColumns?: GridProps['alignItems'];
	paperProps?: PaperProps;
	level?: number;
}

export default function TableRow({
	countColumns = 12,
	sxRow,
	spacingBetweenColumns = 1,
	direction = 'row',
	paperProps,
	alignColumns = 'center',
	children,
	level = 1
}: ITableRow) {
	return (
		<StyledPaper level={level} {...paperProps}>
			<Grid
				container
				columns={countColumns}
				columnSpacing={spacingBetweenColumns}
				direction={direction}
				alignItems={alignColumns}
				sx={sxRow}
			>
				{children}
			</Grid>
		</StyledPaper>
	);
}
