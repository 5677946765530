import React, { useEffect } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoaderWithData from 'components/_layout/LoaderWithData';
import LoadIndicator from 'components/_layout/LoadIndicator';
import { Controller } from 'react-hook-form';
import { StyledCKEditorWrapper } from 'styled/StyledCKEditorWrapper/StyledCKEditorWrapper';
import CKEditor from '@ckeditor/ckeditor5-react';
import Editor from '@juniyadi/ckeditor5-custom-build';

import {
	IGenerateDescriptionResponse,
	useGenerateDescriptionMutation
} from 'store/services/ai.service';
import usePrompts from 'hooks/usePrompts';
import {
	DEFAULT_EDITOR_CONFIG,
	DEFAULT_PROMPTS,
	PROMPT_TYPES_CODE
} from '_constants';
import { useAppSelector } from 'store';
import {
	activeLangSelector,
	gptVersionSelector
} from 'store/slices/masterFormSlice';
import ReplayIcon from '@mui/icons-material/Replay';
import { isFunction } from 'lodash';

export interface GenerateTextProps {
	title?: string;
	control?: any;
	name?: string;
	formValue?: string;
	typePrompt?: string;
	setValue?: any;
	externalPrompt?: string;
	requestValues?: Record<any, any>;
	setLoading?: (value: boolean) => void;
}
function GenerateText({
	title,
	name,
	formValue,
	control,
	typePrompt,
	setValue,
	externalPrompt,
	setLoading,
	requestValues = {}
}: GenerateTextProps) {
	const gptVersion = useAppSelector(gptVersionSelector);
	const activeLang = useAppSelector(activeLangSelector);

	const [generateDescription, { isLoading }] =
		useGenerateDescriptionMutation();

	const { lastPrompt, isLoadingPromptsList } = usePrompts({
		typeCode: typePrompt
	});

	const handleGenerateText = () => {
		if (isFunction(setLoading)) setLoading(true);

		generateDescription({
			prompt:
				externalPrompt ||
				lastPrompt?.prompt ||
				DEFAULT_PROMPTS.DESCRIPTION,
			model: gptVersion,
			activeLang: activeLang,
			...requestValues
		})
			.then((response: { data: IGenerateDescriptionResponse }) =>
				setValue(name, response?.data?.generatedText)
			)
			.finally(() => {
				if (isFunction(setLoading)) setLoading(false);
			});
	};

	useEffect(() => {
		if (formValue || isLoadingPromptsList) return;

		handleGenerateText();
	}, [isLoadingPromptsList]);

	return (
		<Box>
			<Stack
				sx={{ minHeight: 40 }}
				alignItems='center'
				direction='row'
				justifyContent='space-between'
				mb={2}
			>
				<Typography variant='h3' color='mainTextColor'>
					{title}
				</Typography>

				{!isLoading && (
					<IconButton
						aria-label='regenerate text'
						onClick={handleGenerateText}
					>
						<ReplayIcon
							sx={{
								color: (theme) => theme.palette.mainTextColor
							}}
						/>
					</IconButton>
				)}
			</Stack>

			<Box position='relative'>
				{formValue && isLoading ? <LoaderWithData /> : null}

				{!formValue && isLoading ? (
					<LoadIndicator />
				) : (
					<Box sx={{ maxWidth: '100%' }}>
						<Controller
							render={({ field }) => (
								<StyledCKEditorWrapper>
									<CKEditor
										editor={Editor}
										config={DEFAULT_EDITOR_CONFIG}
										data={field.value}
										onChange={(event, editor) =>
											field.onChange(editor.getData())
										}
									/>
								</StyledCKEditorWrapper>
							)}
							name={name}
							control={control}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
}

export default React.memo(GenerateText);
