import React, { useMemo, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { Box, Button } from '@mui/material';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import Grid from '@mui/material/Grid';
import {
	IUploadDataResponse,
	useUploadDataTypeMutation
} from 'store/services/ai.service';
import { useAppSelector } from 'store';
import {
	categoriesSelector,
	filtersSelector,
	formValuesSelector,
	productsSelector
} from 'store/slices/masterFormSlice';
import { useTranslation } from 'react-i18next';

export default function FinishSection() {
	const { t } = useTranslation();

	const { handlePrevStep }: IStepperContext = useGetContext(StepperContext);
	const categories = useAppSelector(categoriesSelector);
	const filters = useAppSelector(filtersSelector);
	const products = useAppSelector(productsSelector);

	const formValues = useAppSelector(formValuesSelector);

	const [uploadData, { isLoading: isUploadingData }] =
		useUploadDataTypeMutation();

	const [isSuccessUploadedData, setIsSuccessUploadedData] = useState(false);

	const companyInfoData = useMemo(
		() => ({
			companyDescription: formValues?.companyDescription,
			companyInfo: formValues?.companyInfo,
			companyUrl: formValues?.companyUrl,
			companyCountry: formValues?.companyCountry,
			companyState: formValues?.companyState,
			companyCity: formValues?.companyCity,
			companyLanguages: formValues?.companyLanguages,
			companyPrimaryLanguage: formValues?.companyPrimaryLanguage,
			companyCurrencies: formValues?.companyCurrencies,
			companyPrimaryCurrency: formValues?.companyPrimaryCurrency,
			companyIndustry: formValues?.companyIndustry,
			companyIndustryId: formValues?.companyIndustryId
		}),
		[formValues]
	);

	const handleUploadData = () => {
		uploadData({ dataType: 'company_info', body: companyInfoData }).then(
			() =>
				Promise.all([
					uploadData({
						dataType: 'nested_categories',
						body: categories
					}),
					uploadData({
						dataType: 'properties',
						body: filters
					})
				]).then(() =>
					uploadData({
						dataType: 'products',
						body: products
					}).then((response: { data: IUploadDataResponse }) => {
						if (!response?.data?.result) return;

						setIsSuccessUploadedData(true);
					})
				)
		);
	};

	return (
		<>
			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			{isSuccessUploadedData ? (
				<Typography
					variant='h1'
					align='center'
					component='h2'
					color='mainTextColor'
				>
					{t('successfully_uploaded_data')}
				</Typography>
			) : (
				<Box>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography
								variant='h1'
								component='h2'
								color='mainTextColor'
							>
								{t('create_website')}
							</Typography>
						</Grid>
					</Grid>

					<Box mt={5} sx={{ maxWidth: 400 }}>
						<Button
							size='large'
							fullWidth
							variant='contained'
							color='primary'
							disabled={isUploadingData}
							onClick={handleUploadData}
						>
							{t('create')}
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
}
