import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			'& svg': {
				color: palette.inputBorder
			}
		},

		input: {}
	}
};
