import React from 'react';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

export const StyledBadgeContainer = styled(Stack)(({ theme }) => ({
	background: theme.palette.secondaryColor,
	minWidth: 40,
	height: 40,
	borderRadius: '50%'
}));

export default function EmptyImageBadge() {
	return (
		<StyledBadgeContainer justifyContent='center' alignItems='center'>
			<ImageOutlinedIcon
				sx={{ color: (theme) => theme.palette.primaryColor }}
			/>
		</StyledBadgeContainer>
	);
}
