import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			color: palette.mainTextColor
		},

		label: {
			color: palette.mainTextColor,
			fontWeight: 400,
			'&.Mui-active, &.Mui-completed': {
				color: palette.mainTextColor,
				fontWeight: 600
			}
		},

		iconContainer: {
			'& .MuiStepIcon-root': {
				color: palette.primaryGray,

				'& .MuiStepIcon-text': {
					fill: palette.bodyColor
				},

				'&.Mui-completed': {
					fill: palette.primaryGreen
				},

				'&.Mui-active': {
					'& .MuiStepIcon-text': {
						fill: palette.mainTextColor
					}
				}
			}
		}
	}
};
