import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function ShowEditPrompt({ state, setState, promptType }) {
	const handlerOnChange = (value) => {
		const d = {
			...state
		};
		d[promptType] = value;
		setState(d);
	};
	return (
		<Box
			component='form'
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' }
			}}
			noValidate
			autoComplete='off'
		>
			<div>
				<TextField
					id='filled-multiline-static'
					label={`Prompt for ${promptType}`}
					multiline
					rows={4}
					value={state[promptType]}
					onChange={(e) => handlerOnChange(e.target.value)}
					variant='filled'
				/>
			</div>
		</Box>
	);
}
