import * as React from 'react';
import Box from '@mui/material/Box';
import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

import Grid from '@mui/material/Grid';
import StartSection, {
	BgImagesStartSection
} from 'pages/MainPage/components/StartSection';
import StepperProvider from 'components/_providers/StepperProvider';
import VerticalLinearStepper, { IStep } from 'components/VerticalLinearStepper';
import Header from 'components/Header';
import CompanyInfoSection, {
	BgImageCompanyInfo
} from 'pages/MainPage/components/CompanyInfoSection';
import DescriptionSection from 'pages/MainPage/components/DescriptionSection';
import CategoriesSection from 'pages/MainPage/components/CategoriesSection';
import { useForm, FormProvider } from 'react-hook-form';
import { formValuesSelector } from 'store/slices/masterFormSlice';
import { useSelector } from 'react-redux';
import ProductsSection from 'pages/MainPage/components/ProductsSection';
import HowFeelProductsSection from 'pages/MainPage/components/HowFeelProductsSection';
import FinishSection from 'pages/MainPage/components/FinishSection';
import AdditionalInfoSection from 'pages/MainPage/components/AdditionalInfoSection';
import MobileStepper from 'components/MobileStepper';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useMasterFormValidation from 'validationChemes/useMasterFormValidation';
import PropertiesSection from './components/PropertiesSection';

export default function MainPage() {
	const { t, i18n } = useTranslation();

	const validationSchema = useMasterFormValidation();

	const formValues = useSelector(formValuesSelector);

	const themeMui = useTheme();
	const mdDown = useMediaQuery(themeMui.breakpoints.down('md'));

	const formContext = useForm({
		values: formValues,
		resolver: yupResolver(validationSchema),
		mode: 'onBlur'
	});

	const steps: IStep[] = [
		{
			label: t('start_section_stepper'),
			component: <StartSection />,
			subComponent: <BgImagesStartSection />
		},
		{
			label: t('company_info_stepper'),
			component: <CompanyInfoSection />,
			subComponent: <BgImageCompanyInfo />
		},
		{
			label: t('activities_stepper'),
			component: <AdditionalInfoSection />
		},
		{
			label: t('description_stepper'),
			component: <DescriptionSection />
		},
		{
			label: t('categories_stepper'),
			component: <CategoriesSection />
		},
		{
			label: t('properties_stepper'),
			component: <PropertiesSection />
		},
		{
			label: t('how_will_fill_products_stepper'),
			component: <HowFeelProductsSection />
		},
		{
			label: t('products_stepper'),
			component: <ProductsSection />
		},
		{
			label: t('finish_stepper'),
			component: <FinishSection />
		}
	];

	return (
		<Box
			sx={{
				display: 'flex',
				minHeight: '100vh',
				flexDirection: 'column'
			}}
		>
			<Box
				sx={{
					flex: 1,
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					backgroundColor: (theme) => theme.palette.bodyColor,
					position: 'relative'
				}}
			>
				<Box
					className='App'
					sx={{
						height: '100%'
					}}
				>
					<Header />

					<Container maxWidth='xl'>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormProvider {...formContext}>
									<StepperProvider>
										{mdDown ? (
											<MobileStepper steps={steps} />
										) : (
											<VerticalLinearStepper
												steps={steps}
											/>
										)}
									</StepperProvider>
								</FormProvider>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Box>
		</Box>
	);
}
