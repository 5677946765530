import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	clone,
	filter,
	find,
	findIndex,
	forEach,
	groupBy,
	isEmpty,
	isFunction,
	map,
	reduce,
	sortBy
} from 'lodash';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack,
	Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { BaseModalProps, IProduct } from 'types';
// import { BaseModalProps, ICategory, IFilter, IProduct } from 'types';
// import ChipsField from 'components/_forms/ChipsField';
import { useAppSelector } from 'store';
import {
	filtersSelector,
	filtersSelectorOptions,
	lastLevelsCategoriesOptionsSelector
} from 'store/slices/masterFormSlice';
import AutocompleteField from 'components/_forms/AutocompleteField';
import { StyledCKEditorWrapper } from 'styled/StyledCKEditorWrapper/StyledCKEditorWrapper';
import { DEFAULT_EDITOR_CONFIG } from '_constants';
import CKEditor from '@ckeditor/ckeditor5-react';
import Editor from '@juniyadi/ckeditor5-custom-build';
import { useTranslation } from 'react-i18next';

export interface IFormValuesEditProducts {
	productName: string;
	productDescription: string;
	id?: string;
	productCategory: any;
	productProperties: any;
}

export interface EditProductModalProps extends BaseModalProps {
	activeProduct?: IProduct;
	handleSave: (values: IFormValuesEditProducts) => void;
}

function EditProductModal({
	isOpen,
	handleClose,
	activeProduct,
	handleSave
}: EditProductModalProps) {
	const { t } = useTranslation();

	const categoriesOptions = useAppSelector(
		lastLevelsCategoriesOptionsSelector
	);
	const propertiesOptions = useAppSelector(filtersSelectorOptions);
	const properties = useAppSelector(filtersSelector);

	const activeProductProperties = () => {
		if (!activeProduct?.properties || isEmpty(activeProduct?.properties))
			return [];

		const newValue = reduce(
			activeProduct?.properties,
			(acc, item) => {
				const newAcc = clone(acc);

				forEach(item?.values, (valueItem) => {
					newAcc.push({
						value: {
							id: item?.id,
							name: item?.name,
							values: valueItem
						},
						label: valueItem?.value || valueItem
					});
				});

				return newAcc;
			},
			[]
		);

		return newValue;
	};

	const activeProductCategory = useMemo(() => {
		if (!activeProduct?.category) return null;

		return {
			value: {
				name: activeProduct?.category?.name,
				id: activeProduct?.category?.id
			},
			label: activeProduct?.category?.name
		};
	}, [activeProduct]);

	const { control, handleSubmit, reset, setValue } = useForm({
		values: {
			productName: activeProduct?.name || '',
			productDescription: activeProduct?.description || '',
			id: activeProduct?.id || '',
			productProperties: activeProductProperties(),
			productCategory: activeProductCategory
		}
	});

	const handleSaveChanges = (data) => {
		if (isFunction(handleSave)) handleSave(data);

		handleClose();
		reset();
	};

	const handleResetChanges = () => {
		handleClose();
		reset();
	};

	const getGroupTagProperties = useCallback(
		(tagValues: any[], tagProps: any) => {
			const sortValues = sortBy(
				tagValues,
				(itemTag) => itemTag?.value?.id
			);

			const groups = groupBy(sortValues, (item) => item?.value?.id);

			const handleDeleteTag = (item) => () => {
				const filterTags = filter(
					tagValues,
					(itemTag) =>
						itemTag?.value?.values?.id !== item?.value?.values?.id
				);

				setValue('productProperties', filterTags);
			};

			return map(
				groups,
				(group: any, keyGroup) =>
					properties?.[keyGroup] && (
						<Box key={keyGroup} mb={0.5} sx={{ width: '100%' }}>
							<Stack
								direction='row'
								flexWrap='wrap'
								alignItems='center'
							>
								<Typography
									color='mainTextColor'
									sx={{ fontWeight: 600 }}
								>
									{properties?.[keyGroup]?.name}:
								</Typography>

								{map(group, (item, index) => (
									<Chip
										key={`${keyGroup}_${index}`}
										label={item?.label}
										{...tagProps(index)}
										onDelete={handleDeleteTag(item)}
									/>
								))}
							</Stack>
						</Box>
					)
			);
		},
		[properties, isOpen, activeProduct]
	);

	return (
		<Dialog open={isOpen} fullWidth maxWidth='sm'>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{activeProduct?.id ? t('edit') : t('create')} {t('product')}
			</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={handleResetChanges}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
					color: (theme) => theme.palette.mainTextColor
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box
					sx={{
						// width: 500,
						maxWidth: '100%'
					}}
				>
					<Stack spacing={3}>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									label={t('name')}
									variant='outlined'
								/>
							)}
							name='productName'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<StyledCKEditorWrapper>
									<CKEditor
										editor={Editor}
										config={DEFAULT_EDITOR_CONFIG}
										data={field.value}
										onChange={(event, editor) =>
											field.onChange(editor.getData())
										}
									/>
								</StyledCKEditorWrapper>
							)}
							name='productDescription'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<AutocompleteField
									handleChange={field.onChange}
									handleBlur={field.onBlur}
									disabled={field.disabled}
									value={field.value}
									name={field.name}
									options={categoriesOptions}
									customIsOptionEqualToValue={(
										option,
										currentOption
									) =>
										option &&
										currentOption &&
										option?.value?.id ===
											currentOption?.value?.id
									}
									label={t('select_product_category')}
									textFieldProps={{ fullWidth: true }}
								/>
							)}
							name='productCategory'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<AutocompleteField
									isMultiple
									handleChange={field.onChange}
									handleBlur={field.onBlur}
									disabled={field.disabled}
									value={field.value}
									limitTags={12}
									groupBy={(option) => option?.value?.name}
									name={field.name}
									options={propertiesOptions}
									customIsOptionEqualToValue={(
										option,
										currentOption
									) =>
										option &&
										currentOption &&
										option?.label ===
											currentOption?.label &&
										option?.value?.id ===
											currentOption?.value?.id
									}
									label={t('select_product_properties')}
									renderTags={getGroupTagProperties}
									textFieldProps={{ fullWidth: true }}
								/>
							)}
							name='productProperties'
							control={control}
						/>
					</Stack>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					size='large'
					onClick={handleSubmit(handleSaveChanges)}
					fullWidth
				>
					{t('save_and_continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default React.memo(EditProductModal);
