import React, { useEffect } from 'react';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import { setFormState } from 'store/slices/masterFormSlice';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box, Button, Radio, RadioGroup } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { METHODS_FILL_PRODUCTS } from '_constants';
import { StyledRadioControlGroup } from 'styled/StyledRadioGroup';
import { useTranslation } from 'react-i18next';

export default function HowFeelProductsSection() {
	const { t } = useTranslation();

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const { control } = useFormContext();

	const methodValue = useWatch({ name: 'preferMethodFill' });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setFormState({ preferMethodFill: methodValue }));
	}, [methodValue]);

	const handleContinue = () => {
		handleNextStep();
	};

	return (
		<div>
			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography
						variant='h1'
						color='mainTextColor'
						component='h2'
					>
						{t('how_will_fill_products_section_title')}
					</Typography>
				</Grid>
			</Grid>

			<Box mt={6}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={11}>
						<Controller
							render={({ field }) => (
								<RadioGroup
									{...field}
									aria-labelledby='demo-radio-buttons-group-label'
								>
									<StyledRadioControlGroup
										isActive={
											methodValue ===
											METHODS_FILL_PRODUCTS.IMPORT
										}
										value={METHODS_FILL_PRODUCTS.IMPORT}
										control={<Radio />}
										label={
											<Box ml={2}>
												<Typography
													color='mainTextColor'
													sx={{
														fontSize: 28,
														fontWeight: 600
													}}
												>
													{t('have_excel')}
												</Typography>
											</Box>
										}
									/>
									<StyledRadioControlGroup
										isActive={
											methodValue ===
											METHODS_FILL_PRODUCTS.GENERATE
										}
										value={METHODS_FILL_PRODUCTS.GENERATE}
										control={<Radio />}
										label={
											<Box ml={2}>
												<Typography
													color='mainTextColor'
													sx={{
														fontSize: 28,
														fontWeight: 600
													}}
												>
													{t(
														'lets_generate_products'
													)}
													<br />
													{t(
														'will_edit_products_later'
													)}
												</Typography>
											</Box>
										}
									/>
								</RadioGroup>
							)}
							name='preferMethodFill'
							control={control}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box mt={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={11}>
						<Button
							variant='contained'
							color='primary'
							size='large'
							fullWidth
							onClick={handleContinue}
							// onClick={handleNextStep}
						>
							{t('save_and_continue')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</div>
	);
}
