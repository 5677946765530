import React, { Suspense, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setTestMode } from 'store/slices/masterFormSlice';

export interface AppWrapperProps {
	children: React.ReactNode;
}
export default function AppWrapper() {
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	useEffect(() => {
		const valueTestMode = searchParams.get('testMode');

		if (valueTestMode) {
			dispatch(setTestMode(true));

			return;
		}

		dispatch(setTestMode(false));
	}, [searchParams]);

	return (
		<Box>
			<Outlet />
		</Box>
	);
}
