import React, { useMemo, useState } from 'react';
import { ICategory, IFilter, IProduct } from 'types';
import { map } from 'lodash';
import { useLazyMergeProductWithDataQuery } from 'store/services/ai.service';
import { useAppSelector } from 'store';
import {
	formValuesSelector,
	gptVersionSelector
} from 'store/slices/masterFormSlice';

export interface MergeProductsDataHookArg {
	categories: ICategory[];
	properties: IFilter[];
}

export default function useMergeProductData({
	categories,
	properties
}: MergeProductsDataHookArg) {
	const gptVersion = useAppSelector(gptVersionSelector);
	const formValues = useAppSelector(formValuesSelector);

	const [loadProductsMergeState, setLoadProductsMergeState] = useState({});
	const [mergeData] = useLazyMergeProductWithDataQuery();

	const transformCategories = useMemo(
		() => map(categories, (item) => ({ id: item?.id, name: item?.name })),
		[categories]
	);

	const handleMergeCategoriesProperties = (product: IProduct) => {
		if (loadProductsMergeState?.[product?.id]) return;

		setLoadProductsMergeState((prevState) => ({
			...prevState,
			[product?.id]: true
		}));

		mergeData({
			id: product?.id,
			product: {
				name: product?.name,
				description: product?.description
			},
			prompt: formValues?.promptProductMerge,
			model: gptVersion,
			categories: transformCategories,
			properties: properties
		}).finally(() =>
			setLoadProductsMergeState((prevState) => ({
				...prevState,
				[product?.id]: false
			}))
		);
	};

	return { handleMergeCategoriesProperties, loadProductsMergeState };
}
