import React from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import TopStartSectionBg from 'images/img/topStartSection.png';
import BottomStartSectionBg from 'images/img/bottomStartSection.png';
import Button from '@mui/material/Button';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';
import { useTranslation } from 'react-i18next';
import {
	initialState,
	isDirtyDataSelector,
	resetData
} from 'store/slices/masterFormSlice';
import ReplayIcon from '@mui/icons-material/Replay';
import { useAppDispatch, useAppSelector } from 'store';
import { useFormContext } from 'react-hook-form';

export const BgImagesStartSection = styled(Box)(({ theme }) => ({
	'&:before': {
		content: `" "`,
		display: 'block',
		position: 'absolute',
		top: 0,
		zIndex: 2,
		right: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url('${TopStartSectionBg}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '100% 0px',
		backgroundSize: '50%'
	},

	'&:after': {
		content: `" "`,
		zIndex: 2,
		display: 'block',
		position: 'absolute',
		bottom: 0,
		right: 0,
		width: '100%',
		height: '100%',
		backgroundImage: `url('${BottomStartSectionBg}')`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom right',
		backgroundSize: '90%'
	}
}));

export default function StartSection() {
	const { handleNextStep }: IStepperContext = useGetContext(StepperContext);

	const { reset } = useFormContext();

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const isDirtyData = useAppSelector(isDirtyDataSelector);

	const handleResetData = () => {
		dispatch(resetData());
		reset();
	};

	return (
		<Box>
			<Box mt={7} mb={8}>
				<Typography
					variant='subtitle2'
					color='mainTextColor'
					gutterBottom
					mb={3}
					sx={{ maxWidth: 1130 }}
				>
					{t('start_section_description')}
				</Typography>

				<Typography variant='h1' color='mainTextColor' mb={4}>
					{t('start_section_title')}
				</Typography>

				<Typography
					align='right'
					variant='subtitle2'
					color='mainTextColor'
					gutterBottom
				>
					{t('start_section_quote')}
					<br />
					{t('start_section_let_ai_do_it')}
				</Typography>

				<Box mt={7}>
					<Stack direction='row' gap={5} alignItems='center'>
						<Button
							variant='contained'
							color='primary'
							size='large'
							onClick={handleNextStep}
						>
							{t('start_section_start_create')}
						</Button>

						<Button
							variant='contained'
							color='secondary'
							size='large'
							disabled={!isDirtyData}
							onClick={handleResetData}
							startIcon={
								<ReplayIcon
									sx={{
										color: (theme) =>
											theme.palette.mainTextColor
									}}
								/>
							}
						>
							{t('reset_data')}
						</Button>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
}
