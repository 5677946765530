import React from 'react';

import Logo from 'images/img/logo.png';
import { Box, Container, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_LIST } from '_constants';
import Typography from '@mui/material/Typography';
import { map } from 'lodash';
import StyledBackgroundWrapper from 'styled/StyledBackgroundWrapper';
import { useNavigate } from 'react-router-dom';

export default function Header() {
	const { i18n } = useTranslation();

	const navigate = useNavigate();

	const handleChangeLanguage = (langCode: string) => () => {
		if (!langCode) return;

		navigate(`/${langCode}`, { replace: true });
		i18n.changeLanguage(langCode);
	};

	return (
		<Box pb={2} pt={4} sx={{ position: 'relative', zIndex: 9 }}>
			<Container maxWidth='xl'>
				<Stack
					gap={2}
					direction='row'
					alignItems='center'
					justifyContent='space-between'
				>
					<Box pl={{ md: 16 }}>
						<img src={Logo} alt='logo' />
					</Box>

					<StyledBackgroundWrapper p={2}>
						<Stack
							direction='row'
							alignItems='center'
							gap={2}
							divider={
								<Divider
									orientation='vertical'
									sx={{
										background: (theme) =>
											theme.palette.mainTextColor
									}}
									flexItem
								/>
							}
						>
							{map(LANGUAGES_LIST, (item, key) => (
								<Typography
									key={key}
									color='mainTextColor'
									onClick={handleChangeLanguage(item?.value)}
									sx={{
										cursor: 'pointer',
										fontWeight:
											i18n?.language === item?.value
												? 600
												: 400
									}}
								>
									{item?.label}
								</Typography>
							))}
						</Stack>
					</StyledBackgroundWrapper>
				</Stack>
			</Container>
		</Box>
	);
}
