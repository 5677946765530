import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledCKEditorWrapper = styled(Box)(({ theme }) => ({
	'& .ck.ck-toolbar': {
		backgroundColor: 'transparent',
		color: 'white!important',
		paddingRight: 75,
		height: 55
	},

	'& .ck-content, & .ck-button': {
		backgroundColor: `transparent !important`,
		color: 'white !important'
	},

	'& button.ck-button:hover:not(.ck-disabled)': {
		backgroundColor: `${theme.palette.secondary.main} !important`
	},

	'& .ck-list__item': {
		backgroundColor: `${theme.palette.bodyColor} !important`
	},

	'& .ck-content table td:hover, & .ck-content table td:focus': {
		backgroundColor: `transparent !important`
	},

	'& .ck-focused': {
		borderColor: 'white !important'
	}
}));
