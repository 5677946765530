import React, { useEffect, useRef } from 'react';
import { BaseModalProps, IProduct } from 'types';
import {
	Alert,
	Button,
	Stack,
	Typography,
	Box,
	DialogTitle,
	IconButton,
	DialogContent,
	DialogActions,
	Dialog
} from '@mui/material';
import useReadExcel from 'hooks/useReadExcel';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { isFunction } from 'lodash';
import { useTranslation } from 'react-i18next';

const DragZoneStyled = styled(Box)(({ theme }) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0
}));

const FileInputStyled = styled('input')(({ theme }) => ({
	display: 'none',
	width: 0,
	height: 0,
	overflow: 'hidden'
}));

export interface ImportProductsModalProps extends BaseModalProps {
	handleSave: (values: IProduct[]) => void;
}

export default function ImportProductsModal({
	isOpen,
	handleClose,
	handleSave
}: ImportProductsModalProps) {
	const { t } = useTranslation();

	const inputFileRef = useRef(null);
	const [dragActive, setDragActive] = React.useState(false);

	const { error, sheet, readFile, fileName, reset } = useReadExcel();

	useEffect(() => {
		reset();
	}, [isOpen]);

	const handleChange = (event) => {
		if (event.target.files[0]) {
			readFile(event.target.files[0]);
		}
	};

	const handleDrop = (event) => {
		event.preventDefault();
		event.stopPropagation();

		setDragActive(false);

		if (event.dataTransfer.files && event.dataTransfer.files[0]) {
			readFile(event.dataTransfer.files[0]);
		}
	};

	const handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	const handleSelectFile = (e) => {
		e.preventDefault();
		inputFileRef.current.click();
	};

	const handleSaveChanges = () => {
		if (!isFunction(handleSave)) return;

		handleSave(sheet);
		handleClose();
	};

	return (
		<Dialog open={isOpen} fullWidth maxWidth='sm'>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{t('import_products_excel')}
			</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
					color: (theme) => theme.palette.mainTextColor
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box
					sx={{
						// width: 500,
						maxWidth: '100%'
					}}
				>
					<Box>
						{error ? (
							<Alert severity='error'>
								{t('something_went_wrong')}
							</Alert>
						) : null}

						<form onDragEnter={handleDrag}>
							<label htmlFor='file'>
								<Stack
									direction='row'
									spacing={1}
									alignItems='center'
								>
									<Button
										onClick={handleSelectFile}
										color='primary'
										variant='contained'
									>
										{t('choose_file')}
									</Button>

									<Typography color='mainTextColor'>
										{fileName || t('no_file_selected')}
									</Typography>
								</Stack>
							</label>

							<FileInputStyled
								id='file'
								name='file'
								type='file'
								ref={inputFileRef}
								onChange={handleChange}
								accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
							/>

							{dragActive && (
								<DragZoneStyled
									onDragEnter={handleDrag}
									onDragLeave={handleDrag}
									onDragOver={handleDrag}
									onDrop={handleDrop}
								/>
							)}
						</form>
					</Box>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					size='large'
					onClick={handleSaveChanges}
					fullWidth
				>
					{t('save_and_continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
