import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
	activeLangSelector,
	filtersSelector,
	formValuesSelector,
	isTestModeSelector,
	lastLevelsCategoriesSelector,
	productsSelector,
	removeProduct,
	resetProducts,
	setFormState,
	setProducts,
	updateProduct
} from 'store/slices/masterFormSlice';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
	IStepperContext,
	StepperContext
} from 'components/_providers/StepperProvider';
import useGetContext from 'hooks/useGetContext';

import { IHeaderColumn } from 'components/_layout/Table/components/TableHeader';
import Table from 'components/_layout/Table';
import { Box, Button, IconButton, InputAdornment, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import LoadIndicator from 'components/_layout/LoadIndicator';
import { clone, find, has, head, includes, isEmpty, map, reduce } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditPrompt from 'components/EditPrompt';
import {
	DEFAULT_PROMPTS,
	METHODS_FILL_PRODUCTS,
	PROMPT_TYPES_CODE
} from '_constants';
import ProductItem from 'components/ProductItem';
import { IImageItem, IProduct } from 'types';
import useToggle from 'hooks/useToggle';
import EditProductModal, {
	IFormValuesEditProducts
} from 'components/_modals/EditProductModal/EditProductModal';
import EditProductImgModal, {
	IFormValuesEditProductsImg
} from 'components/_modals/EditProductImgModal/EditProductImgModal';
import ProductRowSkeleton from 'components/_skeletons/ProductRowSkeleton';
import useGenerateProduct from 'hooks/useGenerateProduct';
import TextField from '@mui/material/TextField';
import ReplayIcon from '@mui/icons-material/Replay';
import usePrompts from 'hooks/usePrompts';
import ImportProductsModal from 'components/_modals/ImportProductsModal';
import { generateIdForEntity } from 'helpers';
import AddIcon from '@mui/icons-material/Add';
import {
	useLazyGenerateImageDalleQuery,
	useLazyGenerateImageGoogleQuery
} from 'store/services/ai.service';
import { useTranslation } from 'react-i18next';

export default function ProductsSection() {
	const { t } = useTranslation();

	const products = useAppSelector(productsSelector);
	const filters = useAppSelector(filtersSelector);
	const leafCategories = useAppSelector(lastLevelsCategoriesSelector);
	const isTestMode = useAppSelector(isTestModeSelector);

	const dispatch = useAppDispatch();

	const { control, setValue } = useFormContext();

	const [generateImageDalle] = useLazyGenerateImageDalleQuery();
	const [generateImageGoogle] = useLazyGenerateImageGoogleQuery();

	const valueMergePrompt = useWatch({ name: 'promptProductMerge', control });

	const formValues = useAppSelector(formValuesSelector);

	const { promptsList, lastPrompt, createPrompt, isLoadingPromptsList } =
		usePrompts({
			typeCode: PROMPT_TYPES_CODE.PRODUCTS
		});

	const { handleNextStep, handlePrevStep }: IStepperContext =
		useGetContext(StepperContext);

	const {
		open: isOpenProductModal,
		handleOpen: handleOpenProductModal,
		handleClose: handleCloseProductModal
	} = useToggle();

	const {
		open: isOpenProductImgModal,
		handleOpen: handleOpenProductImgModal,
		handleClose: handleCloseProductImgModal
	} = useToggle();

	const {
		open: isOpenImport,
		handleOpen: handleOpenImport,
		handleClose: handleCloseImport
	} = useToggle();

	const [editedProduct, setEditedProduct] = useState(null);

	useEffect(() => {
		dispatch(setFormState({ promptProductMerge: valueMergePrompt }));
	}, [valueMergePrompt]);

	const handleResetProducts = () => {
		dispatch(resetProducts());
	};

	const {
		handleGenerateProducts,
		handleGenerateProductsWithData,
		loadProductsByCategoriesState,
		loadProductsMergeState,
		loadProductImagesState,
		loadReplaceProductState,
		handleReplaceProduct: replaceProduct
	} = useGenerateProduct({
		resetProducts: handleResetProducts,
		categories: leafCategories,
		properties: filters,
		description: `for specifications ${JSON.stringify(
			formValues?.companyActivities
		)}, company name ${formValues?.companyInfo}, company url of website ${
			formValues?.companyUrl
		}`,
		prompt:
			formValues.promptProducts ||
			lastPrompt?.prompt ||
			DEFAULT_PROMPTS.PRODUCTS
	});

	useEffect(() => {
		if (formValues?.preferMethodFill === METHODS_FILL_PRODUCTS.IMPORT)
			handleOpenImport();
	}, []);

	useEffect(() => {
		if (
			!isEmpty(products) ||
			isLoadingPromptsList ||
			formValues?.preferMethodFill !== METHODS_FILL_PRODUCTS.GENERATE
		)
			return;

		handleGenerateProducts();
	}, [isLoadingPromptsList]);

	const handleResetPrompt = () => {
		setValue(
			'promptProductMerge',
			DEFAULT_PROMPTS.PRODUCT_CATEGORY_PROPERTY
		);
	};

	const handleEditProduct = (id?: string) => {
		setEditedProduct(products?.[id]);
		handleOpenProductModal();
	};

	const handleEditProductImg = (id?: string) => {
		setEditedProduct(products?.[id]);
		handleOpenProductImgModal();
	};

	const handleCreateProduct = (id?: string) => () => {
		setEditedProduct(products?.[id]);
		handleOpenProductModal();
	};

	const handleReplaceProduct = (id?: string) => {
		if (!id) return;

		const productItem = products?.[id];

		replaceProduct(productItem);
	};

	const handleSaveImagesProduct = (data: IFormValuesEditProductsImg) => {
		dispatch(updateProduct({ id: data?.id, images: data?.images }));
	};

	const handleSaveEdit = (data: IFormValuesEditProducts) => {
		const properties = reduce(
			data?.productProperties,
			(acc, item) => {
				const newAcc = clone(acc);

				newAcc[item?.value?.id] = has(newAcc, item?.value?.id)
					? {
							...newAcc[item?.value?.id],
							values: [
								...newAcc[item?.value?.id].values,
								item?.value?.values
							]
					  }
					: { ...item?.value, values: [item?.value?.values] };

				return newAcc;
			},
			{}
		);

		const productItem = generateIdForEntity({
			description: data?.productDescription,
			properties: Object.values(properties),
			name: data?.productName,
			id: data?.id,
			category: data?.productCategory?.value
		});

		dispatch(updateProduct(productItem));
	};

	const handleRemoveProduct = (id: string) => {
		if (!id) return;
		dispatch(removeProduct(id));
	};

	const headerConfig: IHeaderColumn[] = useMemo(
		() => [
			{
				columnSizeDefault: 0.5,
				label: '#',
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 2,
				label: t('table_name'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: true,
				label: t('table_description'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 1.5,
				label: t('table_category'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 2.5,
				label: t('table_properties'),
				justifyStack: 'left'
			},
			{
				columnSizeDefault: 1,
				label: '',
				justifyStack: 'right'
			}
		],
		[]
	);

	const isLoadingProducts = includes(loadProductsByCategoriesState, true);

	const handleImportProducts = (importProducts) => {
		const createProductsList = reduce(
			importProducts,
			(acc, item) => {
				const newProductItem = {
					name: item?.name,
					description: item?.description
				};

				return [...acc, generateIdForEntity(newProductItem)];
			},
			[]
		);

		dispatch(resetProducts());
		dispatch(setProducts(createProductsList));
		handleGenerateProductsWithData(createProductsList);
	};

	const findPrimaryImage = useCallback((productImages: IImageItem[]) => {
		if (isEmpty(productImages)) return;

		const primaryImage = find(productImages, (item) => item?.isPrimary);

		if (!primaryImage) return head(productImages);

		return primaryImage;
	}, []);

	const handleReplaceImage = () => {
		if (!editedProduct?.id) return;

		const generateFunction = isTestMode
			? generateImageGoogle
			: generateImageDalle;

		return generateFunction({ name: editedProduct?.name });
	};

	return (
		<Box>
			<Box mb={3}>
				{!isLoadingPromptsList && (
					<EditPrompt
						name='promptProducts'
						callbackSavePrompt={createPrompt}
						promptsList={promptsList}
						externalValue={
							formValues?.promptProducts ||
							lastPrompt?.prompt ||
							DEFAULT_PROMPTS.PRODUCTS
						}
						defaultValue={
							lastPrompt?.prompt || DEFAULT_PROMPTS.PRODUCTS
						}
					>
						<Box mt={2}>
							<Controller
								render={({ field }) => (
									<TextField
										{...field}
										size='small'
										fullWidth
										multiline
										minRows={1}
										maxRows={6}
										variant='outlined'
										InputProps={{
											endAdornment: (
												<InputAdornment position='end'>
													<IconButton
														aria-label='reset prompt'
														onClick={
															handleResetPrompt
														}
														edge='end'
													>
														<ReplayIcon
															sx={{
																color: (
																	theme
																) =>
																	theme
																		.palette
																		.mainTextColor
															}}
														/>
													</IconButton>
												</InputAdornment>
											)
										}}
									/>
								)}
								name='promptProductMerge'
								control={control}
							/>
						</Box>
					</EditPrompt>
				)}
			</Box>

			<Button
				onClick={handlePrevStep}
				variant='text'
				startIcon={<ArrowBackIcon />}
				color='primary'
			>
				<Typography>{t('back_to_previous_section')}</Typography>
			</Button>

			<Typography variant='h1' component='h2' color='mainTextColor'>
				{t('products_section_title')}
			</Typography>

			<Box mt={5} position='relative'>
				{isEmpty(products) && isLoadingProducts ? (
					<LoadIndicator />
				) : (
					<Box sx={{ overflowX: 'auto' }}>
						<Box sx={{ minWidth: 980 }}>
							<Table>
								<Table.Header columns={headerConfig} />

								{map(
									Object.values(products),
									(product: IProduct, key) => (
										<ProductItem
											key={`${key}_${product?.id}`}
											isLoadingCategories={
												loadProductsMergeState?.[
													product?.id
												]
											}
											isLoadingImage={
												loadProductImagesState?.[
													product?.id
												]
											}
											name={product?.name}
											category={product?.category}
											properties={product?.properties}
											handleEditProduct={
												handleEditProduct
											}
											handleEditProductImg={
												handleEditProductImg
											}
											handleRemoveProduct={
												handleRemoveProduct
											}
											handleReplaceProduct={
												handleReplaceProduct
											}
											isUpdatingProduct={
												loadReplaceProductState?.[
													product?.id
												]
											}
											description={product?.description}
											numberLine={key + 1}
											image={
												findPrimaryImage(
													product?.images
												)?.src
											}
											id={product?.id}
										/>
									)
								)}
							</Table>
						</Box>
					</Box>
				)}

				{!isEmpty(products) && isLoadingProducts ? (
					<ProductRowSkeleton quantity={2} />
				) : null}

				<Box
					sx={{
						position: 'absolute',
						top: 1,
						right: 0,
						width: 45
					}}
				>
					<IconButton
						sx={{
							color: (theme) => theme.palette.mainTextColor,
							border: 1,
							borderRadius: 1,
							backgroundColor: (theme) => theme.palette.bodyColor,
							width: 45,
							height: 45
						}}
						onClick={handleCreateProduct()}
						size='large'
					>
						<AddIcon />
					</IconButton>
				</Box>
			</Box>

			<Stack mt={10} mb={2} direction='row' spacing={5}>
				<Button
					fullWidth
					onClick={
						formValues?.preferMethodFill ===
						METHODS_FILL_PRODUCTS.IMPORT
							? handleOpenImport
							: handleGenerateProducts
					}
					disabled={isLoadingProducts}
					variant='contained'
					color='secondary'
					size='large'
				>
					{t('try_again')}
				</Button>

				<Button
					fullWidth
					onClick={handleNextStep}
					disabled={isLoadingProducts}
					variant='contained'
					color='primary'
					size='large'
				>
					{t('save_and_continue')}
				</Button>
			</Stack>

			<ImportProductsModal
				handleSave={handleImportProducts}
				isOpen={isOpenImport}
				handleClose={handleCloseImport}
			/>

			<EditProductModal
				isOpen={isOpenProductModal}
				activeProduct={editedProduct}
				handleSave={handleSaveEdit}
				handleClose={handleCloseProductModal}
			/>

			<EditProductImgModal
				isOpen={isOpenProductImgModal}
				activeImages={editedProduct?.images || []}
				id={editedProduct?.id}
				handleSave={handleSaveImagesProduct}
				handleClose={handleCloseProductImgModal}
				replaceImageQuery={handleReplaceImage}
			/>
		</Box>
	);
}
