import { baseApi } from 'store/services/base.service';
import { find, toLower, trim } from 'lodash';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IIndustry, IPrompt, IPromptType } from 'types';

export const promptsApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		getIndustries: build.query<IIndustry[], any>({
			query: () => ({
				url: `/integration/admin/prompt_industries?limit=99&code=Default`,
				method: 'GET'
			}),
			transformResponse: (response: any) => response?.results
		}),

		createIndustry: build.mutation<IIndustry, any>({
			queryFn: async ({ code }, api, options, fetchBQ) => {
				const findExistIndustry: any = await fetchBQ({
					url: `/integration/admin/prompt_industries?code=${trim(
						code
					)}`,
					method: 'GET'
				});

				if (findExistIndustry.error)
					return {
						error: findExistIndustry.error as FetchBaseQueryError
					};

				const checkIndustryItem = find(
					findExistIndustry.data.results,
					(item) => toLower(trim(item?.code)) === toLower(trim(code))
				);

				if (checkIndustryItem) return { data: checkIndustryItem };

				const createIndustry = await fetchBQ({
					url: '/integration/admin/prompt_industries',
					method: 'POST',
					body: { code: trim(code) }
				});

				if (createIndustry.error)
					return {
						error: createIndustry.error as FetchBaseQueryError
					};

				return createIndustry?.data
					? { data: createIndustry.data }
					: { error: createIndustry.error as FetchBaseQueryError };
			}
		}),

		getPromptTypes: build.query<IPromptType[], any>({
			query: () => ({
				url: `/integration/admin/prompt_types`,
				method: 'GET'
			}),
			transformResponse: (response: any) => response?.results
		}),

		getPromptsByType: build.query<IPrompt[], any>({
			query: ({ typeId, industryId }) => ({
				url: `/integration/admin/prompts?prompt_type_id=${typeId}&industry_id=${industryId}`,
				method: 'GET'
			}),
			providesTags: ['PromptsList'],
			transformResponse: (response: any) => response?.results
		}),

		createPrompt: build.mutation<IPrompt, any>({
			query: ({ typeId, industryId, prompt }) => ({
				url: `/integration/admin/prompts`,
				method: 'POST',
				body: {
					prompt: prompt,
					prompt_type_id: typeId,
					industry_id: industryId
				}
			}),
			invalidatesTags: ['PromptsList']
		})
	})
});

export const {
	useGetIndustriesQuery,
	useCreateIndustryMutation,
	useGetPromptTypesQuery,
	useGetPromptsByTypeQuery,
	useCreatePromptMutation
} = promptsApi;
