import {
	createApi,
	fetchBaseQuery,
	retry
} from '@reduxjs/toolkit/dist/query/react';

const customBaseQuery = retry(
	fetchBaseQuery({ baseUrl: 'https://integration.swiss-linker.ch' }),
	{
		maxRetries: 0
	}
);
export const baseApi = createApi({
	baseQuery: customBaseQuery,
	reducerPath: 'Base',
	tagTypes: ['PromptsList'],

	endpoints: () => ({})
});
