import React, { useEffect, useState } from 'react';
import '../../App.css';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

import ButtonAppBar from 'components/ButtonAppBar';
import CompanyDescriptionInput from 'components/CompanyDescriptionInput';
import ShowEditPrompt from 'components/ShowEditPrompt';
import Categories from 'components/Categories';
import GenerateCategories from 'components/GenerateCategories';
import Filters from 'components/Filters';
import GenerateFilters from 'components/GenerateFilters';
import Products from 'components/Products';
import ProductDetail from 'components/ProductDetail';
import GenerateProducts from 'components/GenerateProducts';
import { gptVersionSelector } from 'store/slices/masterFormSlice';
import { useAppSelector } from 'store';
import gpt from '../../gptAPI';
import Prompts from '../../common';

export default function TryDemoPage() {
	const [settings, setSettings] = useState({ model: 'gpt-3.5-turbo' });
	const [company, setCompany] = useState('');
	const [companyAbout, setCompanyAbout] = useState({
		main: 'Company sells cheese.',
		brand: '',
		units: ''
	});
	const [prompts, setPrompts] = useState(Prompts.getInit());
	const [categories, setCategories] = useState([]);
	const [products, setProducts] = useState([]);
	const [filters, setFilters] = useState([]);

	const [productState, setState] = useState({});

	useEffect(() => {
		setCompany(Object.values(companyAbout).join(' '));
	}, [companyAbout]);

	const getProductDetails = (product) => {
		const { name, description } = product;
		const attributes = filters
			.map((obj) => {
				const valuesString = obj.values.join(', ');
				return `${obj.name}(${valuesString})`;
			})
			.join(',');

		gpt.getProductData(
			{
				name,
				description,
				model: settings.model,
				prompt: prompts[Prompts.product],
				categories: categories.map((item) => item.name).join(','),
				attributes
			},
			setState
		);
	};

	return (
		<div className='App'>
			<ButtonAppBar settings={settings} setSettings={setSettings} />
			<br />
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='h4' component='h5'>
							Company description
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<CompanyDescriptionInput
							company={companyAbout}
							setCompany={setCompanyAbout}
						/>
					</Grid>
				</Grid>
				<br />
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='h4' component='h5'>
							Categories
						</Typography>
						<ShowEditPrompt
							state={prompts}
							setState={setPrompts}
							promptType={Prompts.category}
						/>
						<Categories categories={categories} />
						<GenerateCategories
							company={company}
							setCategories={setCategories}
							prompts={prompts}
							settings={settings}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='h4' component='h5'>
							Filters / Attributes
						</Typography>
						<ShowEditPrompt
							state={prompts}
							setState={setPrompts}
							promptType={Prompts.filter}
						/>

						<Filters filters={filters} />
						<GenerateFilters
							company={company}
							setFilters={setFilters}
							prompts={prompts}
							settings={settings}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='h4' component='h5'>
							Products
						</Typography>
						<ShowEditPrompt
							state={prompts}
							setState={setPrompts}
							promptType={Prompts.products}
						/>
						<ShowEditPrompt
							state={prompts}
							setState={setPrompts}
							promptType={Prompts.product}
						/>

						<Products
							products={products}
							getProductDetails={getProductDetails}
						/>
						<ProductDetail productState={productState} />
						<GenerateProducts
							company={company}
							setProducts={setProducts}
							prompts={prompts}
							settings={settings}
						/>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
