import {
	useGenerateProductsMutation,
	useReplaceProductMutation
} from 'store/services/ai.service';
import { ICategory, IFilter, IProduct } from 'types';
import { forEach, isEmpty } from 'lodash';
import { useState } from 'react';
import useMergeProductData from 'hooks/useMergeProductData';
import useGenerateProductImages from 'hooks/useGenerateProductImages';
import { useAppSelector } from 'store';
import {
	activeLangSelector,
	gptVersionSelector
} from 'store/slices/masterFormSlice';

export interface GenerateProductsHookArg {
	categories: ICategory[];
	properties: IFilter[];
	resetProducts: any;
	description: string;
	prompt: string;
}

export default function useGenerateProduct({
	resetProducts,
	categories,
	description,
	properties,
	prompt
}: GenerateProductsHookArg) {
	const gptVersion = useAppSelector(gptVersionSelector);
	const activeLang = useAppSelector(activeLangSelector);

	const [generateProducts] = useGenerateProductsMutation();
	const [replaceProduct] = useReplaceProductMutation();

	const [loadProductsByCategoriesState, setLoadProductsByCategoriesState] =
		useState({});
	const [loadReplaceProductState, setLoadReplaceProductState] = useState({});

	const { handleMergeCategoriesProperties, loadProductsMergeState } =
		useMergeProductData({
			properties,
			categories
		});

	const { handleGenerateImage, loadProductImagesState } =
		useGenerateProductImages();

	const handleGenerateProductsWithData = (productsList) => {
		if (isEmpty(productsList)) return;

		forEach(productsList, (product) => {
			handleMergeCategoriesProperties(product);
			handleGenerateImage(product);
		});
	};

	const handleGenerateProducts = () => {
		resetProducts();

		forEach(categories, (category) => {
			setLoadProductsByCategoriesState((prevState) => ({
				...prevState,
				[category?.id]: true
			}));

			generateProducts({
				description: description,
				prompt: prompt,
				category: category?.name,
				model: gptVersion,
				activeLang: activeLang
			})
				.then((response: any) => {
					forEach(response?.data, (product) => {
						handleMergeCategoriesProperties(product);
						handleGenerateImage(product);
					});
				})
				.finally(() => {
					setLoadProductsByCategoriesState((prevState) => ({
						...prevState,
						[category?.id]: false
					}));
				});
		});
	};

	const handleReplaceProduct = (product: IProduct) => {
		if (!product?.id || !product?.name) return;

		setLoadReplaceProductState((prevState) => ({
			...prevState,
			[product?.id]: true
		}));

		replaceProduct({
			model: gptVersion,
			id: product?.id,
			name: product?.name,
			activeLang: activeLang
		})
			.then(({ data }: { data: Partial<IProduct> }) => {
				const productWithNewDescription = {
					...product,
					description: data?.description
				};

				handleMergeCategoriesProperties(productWithNewDescription);
				handleGenerateImage(productWithNewDescription);
			})
			.finally(() =>
				setLoadReplaceProductState((prevState) => ({
					...prevState,
					[product?.id]: false
				}))
			);
	};

	return {
		handleGenerateProducts,
		loadProductsByCategoriesState,
		loadProductsMergeState,
		loadProductImagesState,
		handleGenerateProductsWithData,
		loadReplaceProductState,
		handleReplaceProduct
	};
}
