import React, { useMemo } from 'react';
import Table from 'components/_layout/Table';
import Typography from '@mui/material/Typography';
import { Box, Chip, IconButton, Skeleton, Stack } from '@mui/material';
import EmptyImageBadge from 'components/EmptyImageBadge';
import Image from 'components/Image';
import EditOrDeleteBox from 'components/EditOrDeleteBox';
import { isEmpty, isFunction, map } from 'lodash';
import { ICategory } from 'types';
import Pruning from 'components/Pruning';
import ReplayIcon from '@mui/icons-material/Replay';

export interface ProductItemProps {
	name: string;
	description: string;
	numberLine: number | string;
	id: string;
	category?: ICategory;
	properties?: any;
	image?: string;
	isLoadingCategories?: boolean;
	handleEditProduct?: (id: string) => void;
	handleEditProductImg?: (id: string) => void;
	handleRemoveProduct?: (id: string) => void;
	handleReplaceProduct?: (id: string) => void;
	isLoadingImage?: boolean;
	isUpdatingProduct?: boolean;
}

function ProductItem({
	name,
	numberLine,
	id,
	description,
	image,
	category,
	properties,
	handleRemoveProduct,
	handleEditProduct,
	handleEditProductImg,
	isLoadingCategories = false,
	isLoadingImage,
	handleReplaceProduct,
	isUpdatingProduct
}: ProductItemProps) {
	const handleRemove = () => {
		handleRemoveProduct(id);
	};

	const handleEdit = () => {
		handleEditProduct(id);
	};

	const handleEditImg = () => {
		handleEditProductImg(id);
	};

	const handleReplace = () => {
		handleReplaceProduct(id);
	};

	const renderImage = useMemo(() => {
		if (!image) return <EmptyImageBadge />;

		return (
			<Box sx={{ minWidth: 40 }}>
				<Image
					src={image}
					alt={name}
					width={40}
					height={40}
					borderRadius='50%'
					objectFit='cover'
				/>
			</Box>
		);
	}, [image, name]);

	return (
		<Table.Row spacingBetweenColumns={2}>
			<Table.Column columnSizeDefault={0.5}>
				<Typography>{numberLine}</Typography>
			</Table.Column>

			<Table.Column columnSizeDefault={2}>
				<Stack
					direction={{ sx: 'column', md: 'row' }}
					gap={2}
					alignItems='center'
				>
					{isLoadingImage ? (
						<Skeleton
							variant='circular'
							sx={{ minWidth: 40 }}
							width={40}
							height={40}
						/>
					) : (
						<Box sx={{ cursor: 'pointer' }} onClick={handleEditImg}>
							{renderImage}
						</Box>
					)}

					<Typography>{name}</Typography>
				</Stack>
			</Table.Column>

			<Table.Column columnSizeDefault>
				{isUpdatingProduct ? (
					<Skeleton variant='text' height={30} />
				) : (
					<Pruning maxHeight={85} numberLine={numberLine}>
						<Typography
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					</Pruning>
				)}
			</Table.Column>

			<Table.Column columnSizeDefault={1.5}>
				{isLoadingCategories ? (
					<Skeleton variant='text' height={30} />
				) : (
					<Stack spacing={1}>
						{category ? (
							<Chip label={category?.name} size='small' />
						) : (
							<Typography>-</Typography>
						)}
					</Stack>
				)}
			</Table.Column>

			<Table.Column columnSizeDefault={2}>
				{isLoadingCategories ? (
					<Skeleton variant='text' height={30} />
				) : (
					<Pruning maxHeight={98} numberLine={numberLine}>
						{properties && !isEmpty(properties) ? (
							map(properties, (item) => (
								<Box mb={1.5} key={item?.id}>
									<Stack
										direction='row'
										flexWrap='wrap'
										gap={1}
										alignItems='center'
									>
										<Typography
											variant='h6'
											component='span'
											sx={{ fontWeight: 600 }}
										>
											{item?.name}:
										</Typography>
										{map(
											item?.values,
											(valueItem, keyValue) => (
												<Chip
													size='small'
													key={`${item?.id}_${keyValue}`}
													label={`${valueItem?.value}`}
												/>
											)
										)}
									</Stack>
								</Box>
							))
						) : (
							<Typography>-</Typography>
						)}
					</Pruning>
				)}
			</Table.Column>

			<Table.Column columnSizeDefault={1.5}>
				<Stack direction='row' justifyContent='right' gap={2}>
					{isFunction(handleReplaceProduct) && (
						<IconButton
							aria-label='edit'
							size='small'
							onClick={handleReplace}
						>
							<ReplayIcon
								sx={{
									color: (theme) =>
										theme.palette.mainTextColor
								}}
							/>
						</IconButton>
					)}

					<EditOrDeleteBox
						handleDelete={handleRemove}
						handleEdit={handleEdit}
						isDisabled={isLoadingCategories || isLoadingImage}
					/>
				</Stack>
			</Table.Column>
		</Table.Row>
	);
}

export default React.memo(ProductItem);
