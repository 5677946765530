import React, { useCallback, useMemo } from 'react';
import Table from 'components/_layout/Table';
import { Box, IconButton, Skeleton, Stack } from '@mui/material';
import EmptyImageBadge from 'components/EmptyImageBadge';
import Typography from '@mui/material/Typography';
import EditOrDeleteBox from 'components/EditOrDeleteBox';
import Image from 'components/Image';
import ReplayIcon from '@mui/icons-material/Replay';
import { isFunction } from 'lodash';

export interface CategoryItemProps {
	level: number;
	name?: string;
	description?: string;
	image?: string;
	handleEditCategory: (id: string) => () => void;
	handleRemoveCategory: (id: string) => void;
	handleEditImage: (id: string) => () => void;
	handleReplaceCategory: (id: string) => () => void;
	isUpdatingItem?: boolean;
	id?: string;
	isLoadingImage?: boolean;
}

function CategoryItem({
	level,
	name,
	description,
	image,
	handleEditCategory,
	handleRemoveCategory,
	id,
	handleEditImage,
	handleReplaceCategory,
	isUpdatingItem,
	isLoadingImage
}: CategoryItemProps) {
	const handleRemove = useCallback(() => {
		handleRemoveCategory(id);
	}, [id]);

	const renderImage = useMemo(() => {
		if (!image) return <EmptyImageBadge />;

		return (
			<Image
				src={image}
				alt={name}
				width={40}
				height={40}
				borderRadius='50%'
				objectFit='cover'
			/>
		);
	}, [image, name]);

	return (
		<Table.Row level={level} spacingBetweenColumns={3}>
			<Table.Column columnSizeDefault={4}>
				<Stack
					direction='row'
					spacing={2}
					alignItems='center'
					sx={{
						paddingLeft:
							!level || level === 1 ? 0 : `${40 * (level - 1)}px`
					}}
				>
					{isLoadingImage ? (
						<Skeleton
							variant='circular'
							sx={{ minWidth: 40 }}
							width={40}
							height={40}
						/>
					) : (
						<Box
							sx={{ cursor: 'pointer', minWidth: 40 }}
							onClick={handleEditImage(id)}
						>
							{renderImage}
						</Box>
					)}

					<Typography sx={{ wordBreak: 'break-word' }}>
						{name}
					</Typography>
				</Stack>
			</Table.Column>

			<Table.Column columnSizeDefault={6}>
				{isUpdatingItem ? (
					<Skeleton variant='text' height={30} />
				) : (
					<Typography
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				)}
			</Table.Column>

			<Table.Column columnSizeDefault={2}>
				<Stack direction='row' justifyContent='right' gap={2}>
					{isFunction(handleReplaceCategory) && (
						<IconButton
							aria-label='edit'
							size='small'
							onClick={handleReplaceCategory(id)}
						>
							<ReplayIcon
								sx={{
									color: (theme) =>
										theme.palette.mainTextColor
								}}
							/>
						</IconButton>
					)}

					<EditOrDeleteBox
						handleDelete={handleRemove}
						handleEdit={handleEditCategory(id)}
					/>
				</Stack>
			</Table.Column>
		</Table.Row>
	);
}

export default React.memo(CategoryItem);
