import palette from 'theming/palette';

export default {
	styleOverrides: {
		root: {},

		paper: {
			backgroundColor: palette.paperColor,
			padding: '8px 16px'
		}
	}
};
