import * as React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import gpt from '../../gptAPI';
import Prompts from '../../common';

export default function GenerateProducts(props) {
	const { company, setProducts, prompts, settings } = props;
	const [state, setState] = useState<any>({});
	const { resProducts, loading } = state;
	const handlerGenerate = () => {
		gpt.generateProducts(
			{
				description: company,
				prompt: prompts[Prompts.products],
				model: settings.model
			},
			setState
		);
	};

	const handlerSave = () => {
		setProducts(resProducts);
	};

	return (
		<Box
			component='form'
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' }
			}}
			noValidate
			autoComplete='off'
		>
			<div>
				<LoadingButton
					variant='contained'
					onClick={() => handlerGenerate()}
					loading={loading}
					loadingPosition='end'
				>
					Generate products
				</LoadingButton>
				<Button variant='outlined' onClick={() => handlerSave()}>
					Save products
				</Button>
			</div>
			<div>
				{/* <div><pre>{JSON.stringify(resCategories, null, 2) }</pre></div> */}
				<pre>{JSON.stringify(resProducts, null, 2)}</pre>
			</div>
		</Box>
	);
}
