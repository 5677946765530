import { useState } from 'react';
import { isFunction } from 'lodash';

export default function useToggle() {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevState) => !prevState);
	};

	return { open, handleOpen, handleClose, handleToggle };
}
