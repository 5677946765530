import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			background: palette.chipBackground
		},

		label: {
			color: palette.mainTextColor
		},

		deleteIcon: {
			color: palette.primaryGray,

			'&:hover': {
				color: palette.mainTextColor
			}
		}
	}
};
