import React, { ReactNode, useEffect } from 'react';
import {
	Stack,
	Box,
	IconButton,
	InputAdornment,
	Fade,
	Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import useToggle from 'hooks/useToggle';
import { setFormState } from 'store/slices/masterFormSlice';
import { useAppDispatch } from 'store';
import { isEmpty, isFunction, map } from 'lodash';
import Carousel from 'components/Carousel';

export interface EditPromptProps {
	name: string;
	defaultValue: string;
	callbackSavePrompt?: (promptValue: string) => void;
	children?: ReactNode;
	externalValue?: string;
	promptsList?: any;
}

export default function EditPrompt({
	name,
	defaultValue,
	children,
	callbackSavePrompt,
	promptsList,
	externalValue
}: EditPromptProps) {
	const { control, setValue } = useFormContext();

	const { open: isOpenField, handleToggle, handleClose } = useToggle();

	const value = useWatch({ name, control });

	const dispatch = useAppDispatch();

	const handleResetPrompt = () => {
		setValue(name, defaultValue);
	};

	const handleSavePrompt = () => {
		handleClose();

		if (isFunction(callbackSavePrompt)) callbackSavePrompt(value);
	};

	const handleClickPrompt = (newPromptValue) => () =>
		setValue(name, newPromptValue);

	useEffect(() => {
		if (externalValue) {
			setValue(name, externalValue);
		}
	}, [externalValue]);

	useEffect(() => {
		dispatch(setFormState({ [name]: value }));
	}, [value]);

	return (
		<Box>
			<Stack
				direction='row'
				justifyContent='right'
				spacing={3}
				alignItems='flex-start'
			>
				<Box sx={{ width: '100%' }}>
					{isOpenField && (
						<Fade in={isOpenField}>
							<Box>
								<Controller
									render={({ field }) => (
										<TextField
											{...field}
											size='small'
											fullWidth
											multiline
											minRows={1}
											maxRows={6}
											variant='outlined'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															aria-label='reset prompt'
															onClick={
																handleResetPrompt
															}
															edge='end'
														>
															<ReplayIcon
																sx={{
																	color: (
																		theme
																	) =>
																		theme
																			.palette
																			.mainTextColor
																}}
															/>
														</IconButton>
													</InputAdornment>
												)
											}}
										/>
									)}
									name={name}
									control={control}
								/>
							</Box>
						</Fade>
					)}
				</Box>

				{isOpenField && callbackSavePrompt ? (
					<Fade in={isOpenField}>
						<Box>
							<IconButton
								onClick={handleSavePrompt}
								sx={{
									borderRadius: 1,
									border: (theme) =>
										`1px solid ${theme.palette.inputBorder}`
								}}
							>
								<CheckIcon
									sx={{
										color: (theme) =>
											theme.palette.mainTextColor
									}}
								/>
							</IconButton>
						</Box>
					</Fade>
				) : null}

				<IconButton
					onClick={handleToggle}
					sx={{
						borderRadius: 1,
						border: (theme) =>
							`1px solid ${theme.palette.inputBorder}`
					}}
				>
					{isOpenField ? (
						<CloseIcon
							sx={{
								color: (theme) => theme.palette.mainTextColor
							}}
						/>
					) : (
						<EditIcon
							sx={{
								color: (theme) => theme.palette.mainTextColor
							}}
						/>
					)}
				</IconButton>
			</Stack>

			{!isEmpty(promptsList) && isOpenField ? (
				<Fade in={isOpenField}>
					<Box mt={5} mb={7}>
						<Carousel id='promptsList' perPage={3} perMove={3}>
							{map(promptsList, (item) => (
								<Carousel.Item key={item?.id}>
									<Box
										onClick={handleClickPrompt(
											item?.prompt
										)}
										sx={{
											cursor: 'pointer',
											transition: '0.3s',
											'&:hover': {
												background: (theme) =>
													theme.palette.primary.dark
											}
										}}
									>
										<Typography color='mainTextColor'>
											{item?.prompt}
										</Typography>
									</Box>
								</Carousel.Item>
							))}
						</Carousel>
					</Box>
				</Fade>
			) : null}

			{isOpenField && (
				<Fade in={isOpenField}>
					<Box>{children}</Box>
				</Fade>
			)}
		</Box>
	);
}
