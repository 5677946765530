import React, { useCallback, useEffect, useMemo } from 'react';
import {
	useCreateIndustryMutation,
	useCreatePromptMutation,
	useGetIndustriesQuery,
	useGetPromptsByTypeQuery,
	useGetPromptTypesQuery
} from 'store/services/prompts.service';
import { find, isEmpty, last, map } from 'lodash';

export interface PromptsDataHookArg {
	typeCode?: string;
}

export default function usePrompts({ typeCode }: PromptsDataHookArg) {
	const {
		data: allIndustries,
		isLoading: isLoadingIndustries,
		isFetching: isFetchingIndustries
	} = useGetIndustriesQuery({});

	const [createIndustry, { isLoading: isCreatingIndustry }] =
		useCreateIndustryMutation();

	const {
		data: allPromptTypes,
		isLoading: isLoadingPromptTypes,
		isFetching: isFetchingPromptTypes
	} = useGetPromptTypesQuery({});

	const [createPrompt, { isLoading: isCreatingPrompt }] =
		useCreatePromptMutation();

	const industriesOptions = useMemo(
		() =>
			map(allIndustries, (item) => ({
				value: item?.id,
				label: item?.code
			})),
		[allIndustries]
	);

	const getDefaultIndustry = useMemo(() => {
		if (isEmpty(allIndustries)) return;

		return find(allIndustries, (item) => item?.code === 'Default');
	}, [allIndustries]);

	const currentPromptType = useMemo(() => {
		if (isEmpty(allPromptTypes) && !typeCode) return;

		return find(allPromptTypes, (item) => item?.code === typeCode);
	}, [allPromptTypes, typeCode]);

	const {
		data: promptsList,
		isLoading: isLoadingPromptsList,
		isFetching: isFetchingPromptsList
	} = useGetPromptsByTypeQuery(
		{ industryId: getDefaultIndustry?.id, typeId: currentPromptType?.id },
		{ skip: !currentPromptType || !getDefaultIndustry?.id }
	);

	const lastPrompt: any = useMemo(() => {
		if (isEmpty(promptsList)) return;

		return last(promptsList);
	}, [promptsList]);

	const handleCreatePrompt = useCallback(
		(promptValue) => {
			if (!promptValue) return;

			createPrompt({
				prompt: promptValue,
				typeId: currentPromptType?.id,
				industryId: getDefaultIndustry?.id
			});
		},
		[getDefaultIndustry, currentPromptType]
	);

	return {
		allIndustries,
		isLoadingIndustries,
		isFetchingIndustries,
		industriesOptions,

		isCreatingIndustry,
		createIndustry,

		allPromptTypes,
		isLoadingPromptTypes,
		isFetchingPromptTypes,
		currentPromptType,

		promptsList,
		isLoadingPromptsList,
		isFetchingPromptsList,
		lastPrompt,

		createPrompt: handleCreatePrompt,
		isCreatingPrompt
	};
}
