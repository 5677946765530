import {
	createEntityAdapter,
	createSelector,
	createSlice,
	EntityState,
	isAnyOf,
	PayloadAction
} from '@reduxjs/toolkit';
import { aiApi } from 'store/services/ai.service';
import { ICategory, IFilter, IProduct } from 'types';
import { TOption } from 'components/_forms/AutocompleteField/AutocompleteField';
import { generateIdForEntity, transformRecursiveEntities } from 'helpers';
import { clone, forEach, isEmpty, map } from 'lodash';
import { DEFAULT_PROMPTS, GPT_VERSIONS } from '_constants';

export interface IMasterFormValues {
	companyDescription?: string;
	companyInfo?: string;
	companyUrl?: string;
	companyCountry?: Partial<TOption>;
	companyState?: string;
	companyCity?: string;
	companyLanguages?: Partial<TOption>[];
	companyPrimaryLanguage?: Partial<TOption>;
	companyCurrencies?: Partial<TOption>[];
	companyPrimaryCurrency?: Partial<TOption>;
	companyIndustry?: string;
	companyIndustryId?: number;
	preferMethodFill?: string;
	promptDescription?: string;
	promptCategories?: string;
	promptFilters?: string;
	promptProducts?: string;
	promptProductMerge?: string;
	companyActivities?: string[];
	companyBenefits?: string;
	companyMission?: string;
	companySlogan?: string;
	companyAboutUs?: string;
}

export interface IMasterFormState {
	formValues: IMasterFormValues;
	categories: EntityState<ICategory>;
	filters: EntityState<IFilter>;
	products: EntityState<IProduct>;
	gptVersion: string;
	isTestMode?: boolean;
}

const categoriesAdapter = createEntityAdapter<ICategory>({
	selectId: (category) => category?.id
});

const filtersAdapter = createEntityAdapter<IFilter>({
	selectId: (filter) => filter?.id
});

const productsAdapter = createEntityAdapter<IProduct>({
	selectId: (product) => product?.id
});

export const initialState: IMasterFormState = {
	categories: categoriesAdapter.getInitialState(),
	filters: filtersAdapter.getInitialState(),
	products: productsAdapter.getInitialState(),
	gptVersion: GPT_VERSIONS.GPT4_TURBO,
	isTestMode: false,

	formValues: {
		companyDescription: '',
		companyInfo: '',
		companyUrl: '',
		companyCountry: { value: 'CH', label: 'Switzerland' },
		companyState: '',
		companyCity: '',
		companyLanguages: [
			{ value: 'EN', label: 'English' },
			{ value: 'FR', label: 'French' },
			{ value: 'DE', label: 'German' },
			{ value: 'IT', label: 'Italian' }
		],
		companyPrimaryLanguage: { value: 'EN', label: 'English' },
		companyCurrencies: [{ value: 'CHF', label: 'CHF' }],
		companyPrimaryCurrency: { value: 'CHF', label: 'CHF' },
		companyActivities: [],
		companyIndustry: '',
		companyIndustryId: null,
		preferMethodFill: '',
		promptDescription: '',
		promptCategories: '',
		promptFilters: '',
		promptProducts: '',
		companyBenefits: '',
		companyMission: '',
		companySlogan: '',
		companyAboutUs: '',
		promptProductMerge: DEFAULT_PROMPTS.PRODUCT_CATEGORY_PROPERTY
	}
};

const setFormStateAction = (
	state,
	{ payload }: PayloadAction<IMasterFormValues>
) => {
	state.formValues = { ...state.formValues, ...payload };
};

const setCategoriesAction = (
	state,
	{ payload }: PayloadAction<ICategory[]>
) => {
	const transformEntities = transformRecursiveEntities(
		payload,
		'sub_categories'
	);

	state.categories = categoriesAdapter.setAll(
		state.categories,
		transformEntities
	);
};

const removeCategoryAction = (state, { payload }: PayloadAction<string>) => {
	state.categories = categoriesAdapter.removeOne(state.categories, payload);
};

const updateCategoryAction = (state, { payload }) => {
	if (!payload?.id) return;

	state.categories = categoriesAdapter.upsertOne(state.categories, payload);
};

const setFiltersAction = (state, { payload }: PayloadAction<IFilter[]>) => {
	const newItems = map(payload, (filter) =>
		generateIdForEntity({
			...filter,
			values: map(filter?.values, (itemValue) =>
				generateIdForEntity({ value: itemValue })
			)
		})
	);

	state.filters = filtersAdapter.setAll(state.filters, newItems);
};

const removeFilterAction = (state, { payload }: PayloadAction<string>) => {
	state.filters = filtersAdapter.removeOne(state.filters, payload);
};

const updateFilterAction = (state, { payload }) => {
	state.filters = filtersAdapter.upsertOne(state.filters, payload);
};

const setProductsAction = (state, { payload }: PayloadAction<IProduct[]>) => {
	state.products = productsAdapter.upsertMany(
		state.products,
		map(payload, (product) => generateIdForEntity(product))
	);
};

const removeProductAction = (state, { payload }: PayloadAction<string>) => {
	state.products = filtersAdapter.removeOne(state.products, payload);
};

const resetProductsAction = (state) => {
	state.products = filtersAdapter.removeAll(state.products);
};

const updateProductAction = (state, { payload }) => {
	if (!payload?.id) return;

	state.products = filtersAdapter.upsertOne(state.products, payload);
};

const setGptVersionAction = (state, { payload }) => {
	state.gptVersion = payload;
};

const setTestModeAction = (state, { payload }: PayloadAction<boolean>) => {
	state.isTestMode = payload;
};

const resetDataAction = (state) => {
	state.products = filtersAdapter.removeAll(state.products);
	state.categories = categoriesAdapter.removeAll(state.categories);
	state.filters = filtersAdapter.removeAll(state.filters);

	state.formValues = initialState.formValues;
};

const masterFormSlice = createSlice({
	name: 'masterForm',
	initialState,
	reducers: {
		setFormState: setFormStateAction,
		setCategories: setCategoriesAction,
		updateCategory: updateCategoryAction,
		removeCategory: removeCategoryAction,
		setFilters: setFiltersAction,
		updateFilter: updateFilterAction,
		removeFilter: removeFilterAction,
		setProducts: setProductsAction,
		updateProduct: updateProductAction,
		removeProduct: removeProductAction,
		resetProducts: resetProductsAction,
		setGptVersion: setGptVersionAction,
		setTestMode: setTestModeAction,
		resetData: resetDataAction
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			aiApi.endpoints.generateCategories.matchFulfilled,
			setCategoriesAction
		);

		builder.addMatcher(
			isAnyOf(aiApi.endpoints.replaceCategory.matchFulfilled),
			updateCategoryAction
		);

		builder.addMatcher(
			aiApi.endpoints.generateFilters.matchFulfilled,
			setFiltersAction
		);

		builder.addMatcher(
			aiApi.endpoints.replaceFilter.matchFulfilled,
			updateFilterAction
		);

		builder.addMatcher(
			aiApi.endpoints.generateProducts.matchFulfilled,
			setProductsAction
		);

		builder.addMatcher(
			isAnyOf(aiApi.endpoints.replaceProduct.matchFulfilled),
			updateProductAction
		);

		builder.addMatcher(
			aiApi.endpoints.mergeProductWithData.matchFulfilled,
			updateProductAction
		);
	}
});

export const categoriesSelector = createSelector(
	[
		(state) =>
			categoriesAdapter
				.getSelectors()
				.selectAll(state.masterFormSlice.categories)
	],
	(items) => {
		const createCategoryTree = (categories, parentId = '') => {
			const categoryTree = [];

			forEach(categories, (category) => {
				const cloneCategory = clone(category);

				if (category.parentId === parentId) {
					const subCategories = createCategoryTree(
						categories,
						cloneCategory.id
					);

					if (!isEmpty(subCategories)) {
						cloneCategory.sub_categories = subCategories;
					}

					categoryTree.push(cloneCategory);
				}
			});

			return categoryTree;
		};

		return createCategoryTree(items, '');
	}
);

export const lastLevelsCategoriesSelector = createSelector(
	[categoriesSelector],
	(items) => {
		const leafCategories = [];

		const findLeafCategories = (category) => {
			if (isEmpty(category?.sub_categories)) {
				leafCategories.push(category);
			} else {
				forEach(category?.sub_categories, (subCategory) =>
					findLeafCategories(subCategory)
				);
			}
		};

		forEach(items, (category) => findLeafCategories(category));

		return leafCategories;
	}
);

export const lastLevelsCategoriesOptionsSelector = createSelector(
	[lastLevelsCategoriesSelector],
	(items) =>
		map(items, (item) => ({
			value: { name: item?.name, id: item?.id },
			label: item?.name
		}))
);

export const categoriesRawSelector = createSelector(
	[(state) => state.masterFormSlice.categories?.entities],
	(items) => items
);

export const parentCategoriesOptionsSelector = createSelector(
	[categoriesRawSelector],
	(items) => {
		const options = map(items, (item) => ({
			value: item?.id,
			label: item?.name
		}));

		return [{ value: '', label: 'Without category' }, ...options];
	}
);

export const filtersSelector = createSelector(
	[(state) => state.masterFormSlice.filters?.entities],
	(items) => items
);

export const filtersSelectorOptions = createSelector(
	[filtersSelector],
	(items) => {
		const options = [];

		forEach(items, (item) => {
			forEach(item?.values, (itemValue) =>
				options.push({
					value: {
						id: item?.id,
						name: item?.name,
						values: itemValue
					},
					label: itemValue?.value
				})
			);
		});

		return options;
	}
);
export const productsSelector = createSelector(
	[(state) => state.masterFormSlice.products?.entities],
	(items) => items
);

export const formValuesSelector = createSelector(
	[(state) => state.masterFormSlice.formValues],
	(data) => data
);

export const activeLangSelector = createSelector(
	formValuesSelector,
	(formValuesState) => formValuesState?.companyPrimaryLanguage?.label
);

export const gptVersionSelector = createSelector(
	[(state) => state.masterFormSlice.gptVersion],
	(item) => item
);

export const isTestModeSelector = createSelector(
	[(state) => state.masterFormSlice.isTestMode],
	(value) => value
);

export const isDirtyDataSelector = createSelector(
	[
		(state) => state.masterFormSlice.formValues,
		(state) =>
			productsAdapter
				.getSelectors()
				.selectAll(state.masterFormSlice.products),
		(state) =>
			filtersAdapter
				.getSelectors()
				.selectAll(state.masterFormSlice.filters),
		(state) =>
			categoriesAdapter
				.getSelectors()
				.selectAll(state.masterFormSlice.categories)
	],
	(masterForm, products, filters, categories) =>
		!isEmpty(filters) ||
		!isEmpty(categories) ||
		!isEmpty(products) ||
		JSON.stringify(masterForm) !== JSON.stringify(initialState.formValues)
);

export const {
	setFormState,
	setCategories,
	updateCategory,
	removeCategory,
	updateFilter,
	removeFilter,
	removeProduct,
	updateProduct,
	resetProducts,
	setGptVersion,
	setProducts,
	setTestMode,
	resetData
} = masterFormSlice.actions;

export default masterFormSlice.reducer;
