import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function CompanyDescriptionInput({ company, setCompany }) {
	return (
		<Box
			component='form'
			sx={{
				'& .MuiTextField-root': { m: 1, width: '25ch' }
			}}
			noValidate
			autoComplete='off'
		>
			<div>
				<TextField
					id='filled-multiline-static'
					label='Main campany description'
					multiline
					rows={4}
					value={company?.main}
					onChange={(e) =>
						setCompany({ ...company, main: e.target.value })
					}
					variant='filled'
				/>
				<TextField
					id='filled-multiline-static'
					label='About used brands'
					multiline
					rows={4}
					value={company?.brand}
					onChange={(e) =>
						setCompany({ ...company, brand: e.target.value })
					}
					variant='filled'
				/>
				<TextField
					id='filled-multiline-static'
					label='About used units'
					multiline
					rows={4}
					value={company?.unit}
					onChange={(e) =>
						setCompany({ ...company, unit: e.target.value })
					}
					variant='filled'
				/>
			</div>
		</Box>
	);
}
