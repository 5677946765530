import palette from '../palette';

export default {
	styleOverrides: {
		root: {
			color: palette.primaryGray,

			'&.Mui-focused': {
				color: palette.primaryGray
			},

			'&.Mui-disabled': {
				color: palette.primaryGray,
				opacity: 0.5
			}
		}
	}
};
