import React, { useState } from 'react';
import {
	useLazyGenerateImageDalleQuery,
	useLazyGenerateImageGoogleQuery
} from 'store/services/ai.service';
import { IProduct } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import {
	isTestModeSelector,
	updateProduct
} from 'store/slices/masterFormSlice';

export default function useGenerateProductImages() {
	const [generateImageDalle] = useLazyGenerateImageDalleQuery();
	const [generateImageGoogle] = useLazyGenerateImageGoogleQuery();

	const [loadProductImagesState, setLoadProductImagesState] = useState({});

	const isTestMode = useAppSelector(isTestModeSelector);

	const dispatch = useAppDispatch();

	const handleGenerateImage = (product: IProduct) => {
		if (!product?.name || !product?.id) return;

		setLoadProductImagesState((prevState) => ({
			...prevState,
			[product?.id]: true
		}));

		const generateImageFunction = isTestMode
			? generateImageGoogle
			: generateImageDalle;

		generateImageFunction({
			name: product?.name
		})
			.then((response: any) => {
				dispatch(
					updateProduct({
						id: product?.id,
						images: [response?.data?.image]
					})
				);
			})
			.finally(() => {
				setLoadProductImagesState((prevState) => ({
					...prevState,
					[product?.id]: false
				}));
			});
	};

	return { handleGenerateImage, loadProductImagesState };
}
