const bodyColor = '#0F0229';
const mainTextColor = '#ffffff';
const primaryGreen = '#36BB2B';
const primaryGray = '#B8B8B8';
const secondaryGray = '#3b3b3b';
const inputBorder = '#ffffff';
const paperColor = '#1D1136';
const autocompleteFocus = '#2C1D4B';
const chipBackground = '#2C1D4B';
const oddTableColor = '#0F0229';
const evenTableColor = '#1D1136';
const secondaryColor = '#D0C1E2';
const primaryColor = '#1D1136';
const secondaryPaperColor = 'rgba(15, 2, 41, 0.80)';
export default {
	bodyColor,
	mainTextColor,
	primaryGreen,
	primaryGray,
	inputBorder,
	paperColor,
	secondaryPaperColor,
	autocompleteFocus,
	chipBackground,
	oddTableColor,
	evenTableColor,
	secondaryGray,
	secondaryColor,
	primaryColor,

	primary: {
		main: '#4462CA'
	},
	secondary: {
		main: '#56287D'
	}
};
