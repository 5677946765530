import React from 'react';
import { CircularProgress, Stack } from '@mui/material';

export interface ILoadIndicator {
	color?: 'primary' | 'secondary';
}

export default function LoadIndicator({ color = 'primary' }: ILoadIndicator) {
	return (
		<Stack direction='row' justifyContent='center'>
			<CircularProgress color={color} />
		</Stack>
	);
}
