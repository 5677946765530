import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isFunction } from 'lodash';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { BaseModalProps, IFilter } from 'types';
import ChipsField from 'components/_forms/ChipsField';
import { useTranslation } from 'react-i18next';

export interface IFormValuesEditFilters {
	filterName: string;
	filterValues: string[];
	id?: string;
}

export interface EditFilterModalProps extends BaseModalProps {
	activeFilter?: IFilter;
	handleSave: (values: IFormValuesEditFilters) => void;
}

function EditFilterModal({
	isOpen,
	handleClose,
	activeFilter,
	handleSave
}: EditFilterModalProps) {
	const { t } = useTranslation();

	const { control, handleSubmit, reset } = useForm({
		values: {
			filterName: activeFilter?.name || '',
			filterValues: activeFilter?.values || '',
			id: activeFilter?.id || ''
		}
	});

	const handleSaveChanges = (data) => {
		if (isFunction(handleSave)) handleSave(data);

		handleClose();
	};

	const handleResetChanges = () => {
		handleClose();
		reset();
	};

	return (
		<Dialog open={isOpen} fullWidth maxWidth='sm'>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{activeFilter?.id ? t('edit') : t('create')} {t('property')}
			</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={handleResetChanges}
				sx={{
					position: 'absolute',
					right: 12,
					top: 8,
					color: (theme) => theme.palette.mainTextColor
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>
				<Box
					sx={{
						// width: 500,
						maxWidth: '100%'
					}}
				>
					<Stack spacing={3}>
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									fullWidth
									label={t('name')}
									variant='outlined'
								/>
							)}
							name='filterName'
							control={control}
						/>

						<Controller
							render={({ field }) => (
								<ChipsField
									name={field?.name}
									value={field?.value}
									handleBlur={field?.onBlur}
									handleChange={field?.onChange}
									label={t('property_values')}
								/>
							)}
							name='filterValues'
							control={control}
						/>
					</Stack>
				</Box>
			</DialogContent>

			<DialogActions>
				<Button
					variant='contained'
					color='primary'
					size='large'
					onClick={handleSubmit(handleSaveChanges)}
					fullWidth
				>
					{t('save_and_continue')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default React.memo(EditFilterModal);
