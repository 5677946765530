import React, { useCallback } from 'react';
import Table from 'components/_layout/Table';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Skeleton, Stack } from '@mui/material';
import { isFunction, map } from 'lodash';
import EditOrDeleteBox from 'components/EditOrDeleteBox';
import ReplayIcon from '@mui/icons-material/Replay';

export interface FilterItemProps {
	name: string;
	numberLine: number | string;
	values: any;
	handleEditFilter?: (id: string) => void;
	handleRemoveFilter?: (id: string) => void;
	handleReplaceFilter?: (id: string) => void;
	isUpdatingFilter?: boolean;
	id?: string;
}

export default function FilterItem({
	name,
	values,
	numberLine,
	handleEditFilter,
	handleRemoveFilter,
	handleReplaceFilter,
	isUpdatingFilter,
	id
}: FilterItemProps) {
	const handleRemove = useCallback(() => {
		handleRemoveFilter(id);
	}, [id]);

	const handleEdit = useCallback(() => {
		handleEditFilter(id);
	}, [id, handleEditFilter]);

	const handleReplace = useCallback(() => {
		handleReplaceFilter(id);
	}, [id, handleReplaceFilter]);

	return (
		<Table.Row spacingBetweenColumns={4}>
			<Table.Column columnSizeDefault={1}>
				<Typography>{numberLine}</Typography>
			</Table.Column>

			<Table.Column columnSizeDefault={3}>
				<Typography>{name}</Typography>
			</Table.Column>

			<Table.Column columnSizeDefault>
				{isUpdatingFilter ? (
					<Skeleton variant='text' height={30} />
				) : (
					<Box component='ul' pl={2} m={0}>
						{map(values, (filterOption) => (
							<Typography
								key={`${name}_${filterOption?.id}`}
								component='li'
								color='mainTextColor'
							>
								{filterOption?.value}
							</Typography>
						))}
					</Box>
				)}
			</Table.Column>

			<Table.Column columnSizeDefault={2}>
				<Stack direction='row' justifyContent='right' gap={2}>
					{isFunction(handleReplaceFilter) && (
						<IconButton
							aria-label='edit'
							size='small'
							onClick={handleReplace}
						>
							<ReplayIcon
								sx={{
									color: (theme) =>
										theme.palette.mainTextColor
								}}
							/>
						</IconButton>
					)}

					<EditOrDeleteBox
						handleDelete={handleRemove}
						handleEdit={handleEdit}
					/>
				</Stack>
			</Table.Column>
		</Table.Row>
	);
}
