import React, { useState, useRef, useEffect, ReactNode } from 'react';
import Button from '@mui/material/Button';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import palette from '../../theming/palette';

export interface PruningProps {
	children: ReactNode;
	maxHeight: number;
	numberLine: number;
}
export interface StyledOverlayProps extends BoxProps {
	backgroundPruning?: string;
}

export const StyledOverlay = styled(Box, {
	shouldForwardProp: (propName: PropertyKey) =>
		propName !== 'backgroundPruning'
})<StyledOverlayProps>(({ backgroundPruning }) => ({
	'&::after': {
		content: '""',
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		height: '60%',
		background: `linear-gradient(to bottom, ${backgroundPruning}00, ${backgroundPruning}FF 90%)`
	}
}));

export default function Pruning({ children, maxHeight, numberLine }) {
	const { t } = useTranslation();

	const [isExpanded, setExpanded] = useState(false);
	const [childrenHeight, setChildrenHeight] = useState<number | null>(null);
	const contentRef = useRef<HTMLDivElement>(null);
	const isEven = (num: number): boolean => num % 2 === 0;

	useEffect(() => {
		if (contentRef.current) {
			setChildrenHeight(contentRef.current.clientHeight);
		}
	}, [children]);

	const toggleExpansion = () => {
		setExpanded(!isExpanded);
	};

	const backgroundPruning = isEven(numberLine)
		? palette.oddTableColor
		: palette.evenTableColor;

	return (
		<div>
			<Box
				sx={{
					maxHeight: isExpanded ? 'none' : maxHeight,
					overflow: 'hidden',
					position: 'relative'
				}}
			>
				<div ref={contentRef}>{children}</div>
				{!isExpanded &&
					childrenHeight &&
					childrenHeight > maxHeight && (
						<StyledOverlay backgroundPruning={backgroundPruning} />
					)}
			</Box>
			{childrenHeight && childrenHeight > maxHeight && (
				<Button onClick={toggleExpansion} color='primary'>
					{isExpanded ? t('show_less') : t('show_more')}
				</Button>
			)}
		</div>
	);
}
