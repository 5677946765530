import { baseApi } from 'store/services/base.service';
import { IActivityCompany, ICategory, IFilter, IProduct } from 'types';
import { find, forEach, isEmpty, map, sample } from 'lodash';
import { generateIdForEntity } from 'helpers';

export interface IBaseAiResponse {
	model: string;
	prompt: string;
}

export interface IGenerateCategoriesResponse extends IBaseAiResponse {
	result: {
		categories: ICategory[];
	};
}

export interface IReplaceCategoryResponse extends IBaseAiResponse {
	result: {
		category: ICategory;
	};
}

export interface IGenerateFiltersResponse extends IBaseAiResponse {
	result: {
		filters: IFilter[];
	};
}

export interface IReplaceFilterResponse extends IBaseAiResponse {
	result: {
		filter: IFilter;
	};
}

export interface IGenerateProductsResponse extends IBaseAiResponse {
	result: {
		products: IProduct[];
	};
}

export interface IReplaceProductResponse extends IBaseAiResponse {
	result: {
		product: Partial<IProduct>;
	};
}

export interface IMergeFiltersResponse extends IFilter {
	valuesIds: string[];
}

export interface IMergeProductResponse {
	id: string;
	category: ICategory;
	properties: IMergeFiltersResponse[];
}

export interface IGenerateArgs {
	model?: string;
	prompt?: string;
	description?: string;
	response_example?: string;
	activeLang?: string;
}

export interface IReplaceCategoryArgs extends IGenerateArgs {
	id: string;
	name?: string;
}

export interface IReplaceFilterArgs extends IGenerateArgs {
	id: string;
	name: string;
	specifications?: string[];
	companyName?: string;
}

export interface IReplaceProductArgs extends IGenerateArgs {
	id: string;
	name: string;
}

export interface IMergeProductArgs extends IGenerateArgs {
	product: IProduct;
	categories?: Pick<ICategory, 'id' | 'name'>[];
	properties?: IFilter[];
	id: string;
}

export interface IGenerateProductsArgs extends IGenerateArgs {
	category: string;
}

export interface IGenerateDescriptionResponse {
	generatedText: string;
}

export interface IGenerateActivitiesResponse {
	companyActivities: IActivityCompany[];
}

export interface IGenerateImageArgsBase {
	name: string;
}

export interface IGenerateImageCategoryArgs extends IGenerateImageArgsBase {
	id: string;
}

export interface IGenerateImagesProductArgs extends IGenerateImageArgsBase {
	id: string;
}

export interface IUploadDataResponse {
	result: boolean;
}

export interface IGenerateUploadUrlResponse {
	Key: string;
	uploadURL: string;
}

export const aiApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		generateCategories: build.mutation<ICategory[], IGenerateArgs>({
			query: ({ activeLang, ...arg }) => ({
				url: '/api-public/integration/ai/generate_categories',
				method: 'POST',
				body: {
					...arg,
					prompt: `${arg?.prompt}. Result should be in ${
						activeLang || 'en'
					} language`
				}
			}),
			transformResponse: (response: IGenerateCategoriesResponse) => {
				const recursiveSetId = (category: ICategory) => ({
					...generateIdForEntity(category),
					sub_categories: map(category?.sub_categories, (item) =>
						recursiveSetId(item)
					)
				});

				const transformPayload = map(
					response?.result?.categories,
					(item) => recursiveSetId(item)
				);

				return transformPayload;
			}
		}),

		replaceCategory: build.mutation<
			Partial<ICategory>,
			IReplaceCategoryArgs
		>({
			query: ({ model, id, activeLang, ...arg }) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					model,
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <json> only json text. Result should be in ${
						activeLang || 'en'
					} language. Like: {category: {description: ...}}`,
					prompt: `Make new short description for category by name using this json data, ${JSON.stringify(
						arg
					)}`
				}
			}),
			transformResponse: (
				response: IReplaceCategoryResponse,
				meta,
				arg
			) => ({
				id: arg?.id,
				description: response?.result?.category?.description
			})
		}),

		generateFilters: build.mutation<IFilter[], IGenerateArgs>({
			query: ({ activeLang, ...arg }) => ({
				url: '/api-public/integration/ai/generate_filters',
				method: 'POST',
				body: {
					...arg,
					prompt: `${arg?.prompt}. Result should be in ${
						activeLang || 'en'
					} language`
				}
			}),
			transformResponse: (response: IGenerateFiltersResponse) =>
				response?.result?.filters
		}),

		replaceFilter: build.mutation<Partial<IFilter>, IReplaceFilterArgs>({
			query: ({
				model,
				id,
				activeLang,
				specifications,
				name,
				...args
			}) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					model,
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <json> only json text. Result should be in ${
						activeLang || 'en'
					} language. Like: {filter: {values: [name1, name2, ]}}`,
					prompt: `Make a new examples filter attributes for real products. Filter name is ${name}. Company specifications ${JSON.stringify(
						specifications
					)} using this json data, ${JSON.stringify(args)}`
				}
			}),
			transformResponse: (
				response: IReplaceFilterResponse,
				meta,
				arg
			) => ({
				id: arg?.id,
				values: map(response?.result?.filter?.values, (itemValue) =>
					generateIdForEntity({ value: itemValue })
				)
			})
		}),

		generateProducts: build.mutation<IProduct[], IGenerateProductsArgs>({
			query: ({ prompt, category, activeLang, ...body }) => ({
				url: '/api-public/integration/ai/generate_products',
				method: 'POST',
				body: {
					...body,
					prompt: `${prompt} for category ${category}. Result should be in ${
						activeLang || 'en'
					} language.`
				}
			}),
			transformResponse: (response: IGenerateProductsResponse) =>
				Array.isArray(response?.result?.products)
					? map(response?.result?.products, (product) =>
							generateIdForEntity({
								name: product?.name,
								description: product?.description
							})
					  )
					: []
		}),

		replaceProduct: build.mutation<Partial<IProduct>, IReplaceProductArgs>({
			query: ({ name, model, activeLang }) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					prompt: `Make description for product ${name}`,
					model: model,
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <json> only json text. Result should be in ${
						activeLang || 'en'
					} language. Like: {product: {description: ...}}`
				}
			}),
			transformResponse: (
				response: IReplaceProductResponse,
				meta,
				arg
			) => ({
				id: arg?.id,
				description: response?.result?.product?.description
			})
		}),

		generateDescription: build.mutation<IGenerateDescriptionResponse, any>({
			query: ({ prompt, model, activeLang, ...body }) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					model: model,
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <json> only json text. Result should be in ${
						activeLang || 'en'
					} language. Like: {generatedText: string}`,
					prompt: `${prompt} using this json data, ${JSON.stringify(
						body
					)}`
				}
			}),
			transformResponse: (response: any) => response?.result
		}),

		generateActivities: build.mutation<IGenerateActivitiesResponse, any>({
			query: ({ prompt, model, body, activeLang }) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					model: model,
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <\`\`\`json\\n> only json text. Result should be in ${
						activeLang || 'en'
					} language. Like: {companyActivities: [{classifier, classificationCode, classificationDescription}]}]}`,
					prompt: `${prompt} using this json data, ${JSON.stringify(
						body
					)}`
				}
			}),
			transformResponse: (response: any) => response?.result
		}),

		generateImageDalle: build.query<any, IGenerateImageArgsBase>({
			query: ({ name }) => ({
				url: `/api-public/integration/image-generation`,
				method: 'POST',
				body: { prompt: name }
			}),

			transformResponse: (response: any) => ({
				image: { src: response?.url }
			})
		}),

		generateImageGoogle: build.query<any, IGenerateImageArgsBase>({
			query: ({ name }) => ({
				url: `/api-public/integration/image_search?q=${name}`,
				method: 'GET'
			}),

			transformResponse: (response: any) => ({
				image: { src: sample(response?.items)?.link }
			})
		}),

		generateImagePixel: build.query<any, IGenerateImageArgsBase>({
			query: ({ name }) => ({
				url: `/api-public/integration/category_search?q=${name}`,
				method: 'GET'
			}),

			transformResponse: (response: any) => ({
				image: { src: response?.photos?.[0]?.src?.medium }
			})
		}),

		mergeProductWithData: build.query<
			IMergeProductResponse,
			IMergeProductArgs
		>({
			query: ({
				product,
				categories,
				properties,
				prompt,
				model
			}: IMergeProductArgs) => ({
				url: '/api-public/integration/ai/custom-prompt',
				method: 'POST',
				body: {
					response_example: `Imagine you're an API and only reply in json format. Without formatting like <\`\`\`json\\n> only json text.. Like: {product: { category: {id: ..., name: ...}, properties: [{id: id, valuesIds: []}]}}`,
					prompt: `${prompt} for the product ${JSON.stringify(
						product
					)}  based on json data with short properties group. Categories: ${JSON.stringify(
						categories
					)}, Properties: ${JSON.stringify(
						Object.values(properties)
					)}`,
					model: model
				}
			}),

			transformResponse: (response: any, meta, arg: any) => {
				const transformPropertiesArray = [];

				forEach(response?.result?.product?.properties, (item) => {
					const filterGroup = arg.properties?.[item?.id];

					if (!filterGroup) return;

					const filterValues = [];

					forEach(item?.valuesIds, (filterId) => {
						const findFilter = find(
							filterGroup?.values,
							(itemSearch) => itemSearch?.id === filterId
						);

						if (findFilter) filterValues.push(findFilter);
					});

					if (isEmpty(filterValues)) return;

					transformPropertiesArray.push({
						...filterGroup,
						values: filterValues
					});
				});

				const findCategory = find(
					arg?.categories,
					(item) =>
						item?.id === response?.result?.product?.category ||
						item?.name === response?.result?.product?.category ||
						item?.name ===
							response?.result?.product?.category?.name ||
						item?.id === response?.result?.product?.category?.id
				);

				return {
					id: arg?.id,
					category: findCategory,
					properties: transformPropertiesArray
				};
			}
		}),

		uploadDataType: build.mutation<IUploadDataResponse, any>({
			query: ({ dataType, body }) => ({
				url: `https://client1-server.swiss-linker.ch/internal/init_data/${dataType}`,
				method: 'POST',
				body: body
			})
		}),

		generateUrlUpload: build.mutation<IGenerateUploadUrlResponse, any>({
			query: () => ({
				url: 'https://oz3cis9uwb.execute-api.eu-west-1.amazonaws.com/uploads',
				method: 'GET'
			})
		}),

		uploadImage: build.mutation<IGenerateDescriptionResponse, any>({
			query: ({ url, file }) => ({
				url: url,
				method: 'PUT',
				body: file
			})
		})
	})
});

export const {
	useGenerateCategoriesMutation,
	useGenerateFiltersMutation,
	useGenerateProductsMutation,
	useGenerateDescriptionMutation,
	useLazyMergeProductWithDataQuery,
	useUploadDataTypeMutation,
	useGenerateUrlUploadMutation,
	useUploadImageMutation,
	useGenerateActivitiesMutation,
	useReplaceCategoryMutation,
	useReplaceFilterMutation,
	useReplaceProductMutation,

	useLazyGenerateImageDalleQuery,
	useLazyGenerateImageGoogleQuery,
	useLazyGenerateImagePixelQuery
} = aiApi;
