import React, { Suspense, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import TryDemoPage from 'pages/TryDemoPage';
import { ThemeProvider } from '@mui/material/styles';
import {
	CircularProgress,
	GlobalStyles,
	StyledEngineProvider
} from '@mui/material';
import { mainTheme } from 'theming/theme';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistMasterForm, store } from 'store';
import GlobalCss from 'theming/GlobalCss';
import AppWrapper from 'components/AppWrapper';
import { I18nextProvider } from 'react-i18next';
import i18n from 'helpers/i18n';
import { includes } from 'lodash';
import { LANGUAGES_CODES } from '_constants';

export default function App() {
	useEffect(() => {
		const currentLanguage = i18n.language;

		if (!includes(LANGUAGES_CODES, currentLanguage)) {
			i18n.changeLanguage(LANGUAGES_CODES[0]);
		}
	}, []);

	return (
		<I18nextProvider i18n={i18n}>
			<ThemeProvider theme={mainTheme}>
				<StyledEngineProvider injectFirst>
					<GlobalStyles styles={GlobalCss} />

					<Provider store={store}>
						<PersistGate
							loading={null}
							persistor={persistMasterForm}
						>
							<Suspense fallback={<CircularProgress />}>
								<BrowserRouter>
									<Routes>
										<Route element={<AppWrapper />}>
											<Route
												path='/:locale?/'
												element={<MainPage />}
											/>
											<Route
												path='/try'
												element={<TryDemoPage />}
											/>
										</Route>
									</Routes>
								</BrowserRouter>
							</Suspense>
						</PersistGate>
					</Provider>
				</StyledEngineProvider>
			</ThemeProvider>
		</I18nextProvider>
	);
}
