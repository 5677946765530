import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { COUNTRIES } from '_constants/countries';
import AutocompleteField from 'components/_forms/AutocompleteField';
import { LANGUAGES } from '_constants/languages';
import { CURRENCIES } from '_constants/currencies';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { isEmpty, isFunction, head } from 'lodash';
import { IMasterFormValues } from 'store/slices/masterFormSlice';
import { useTranslation } from 'react-i18next';

export interface CompanyInfoProps {
	handleChangeValues: (values: IMasterFormValues) => void;
}

export default function CompanyInfo({ handleChangeValues }: CompanyInfoProps) {
	const { t } = useTranslation();
	const { control, setValue } = useFormContext();

	const values = useWatch();

	useEffect(() => {
		if (!isFunction(handleChangeValues)) return;

		if (
			(!values.companyPrimaryLanguage ||
				!values?.companyPrimaryCurrency) &&
			head(values?.companyLanguages) &&
			head(values?.companyCurrencies)
		) {
			setValue('companyPrimaryLanguage', head(values?.companyLanguages));
			setValue('companyPrimaryCurrency', head(values?.companyCurrencies));
		}

		handleChangeValues(values);
	}, [values]);

	return (
		<Grid container spacing={4}>
			<Grid item md={6} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							fullWidth
							variant='outlined'
							required
							error={Boolean(fieldState?.error)}
							helperText={fieldState?.error?.message}
							label={t('company_name')}
						/>
					)}
					name='companyInfo'
					control={control}
				/>
			</Grid>

			<Grid item md={6} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<TextField
							{...field}
							fullWidth
							variant='outlined'
							error={Boolean(fieldState?.error)}
							helperText={fieldState?.error?.message}
							label={t('company_url')}
							required
						/>
					)}
					name='companyUrl'
					control={control}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<AutocompleteField
							handleChange={field.onChange}
							handleBlur={field.onBlur}
							disabled={field.disabled}
							value={field.value}
							name={field.name}
							options={COUNTRIES}
							label={t('company_country')}
							textFieldProps={{
								fullWidth: true,
								error: Boolean(fieldState?.error),
								helperText: fieldState?.error?.message,
								required: true
							}}
						/>
					)}
					name='companyCountry'
					control={control}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Controller
					render={({ field }) => (
						<TextField
							{...field}
							fullWidth
							variant='outlined'
							label={t('company_state')}
						/>
					)}
					name='companyState'
					control={control}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Controller
					render={({ field }) => (
						<TextField
							{...field}
							fullWidth
							variant='outlined'
							label={t('company_city')}
						/>
					)}
					name='companyCity'
					control={control}
				/>
			</Grid>

			<Grid item md={8} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<AutocompleteField
							handleChange={field.onChange}
							handleBlur={field.onBlur}
							disabled={field.disabled}
							value={field.value}
							name={field.name}
							options={LANGUAGES}
							isMultiple
							label={t('company_languages')}
							textFieldProps={{
								fullWidth: true,
								required: true,
								error: Boolean(fieldState?.error),
								helperText: fieldState?.error?.message
							}}
						/>
					)}
					name='companyLanguages'
					control={control}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<AutocompleteField
							handleChange={field.onChange}
							handleBlur={field.onBlur}
							value={field.value}
							disabled={
								field.disabled ||
								isEmpty(values?.companyLanguages)
							}
							name={field.name}
							options={
								!isEmpty(values?.companyLanguages)
									? values?.companyLanguages
									: []
							}
							label={t('company_primary_language')}
							textFieldProps={{
								fullWidth: true,
								required: true,
								error: Boolean(fieldState?.error),
								helperText: fieldState?.error?.message
							}}
						/>
					)}
					name='companyPrimaryLanguage'
					control={control}
				/>
			</Grid>

			<Grid item md={8} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<AutocompleteField
							handleChange={field.onChange}
							handleBlur={field.onBlur}
							value={field.value}
							disabled={field.disabled}
							name={field.name}
							options={CURRENCIES}
							isMultiple
							label={t('company_currencies')}
							textFieldProps={{
								fullWidth: true,
								required: true,
								error: Boolean(fieldState?.error),
								helperText: fieldState?.error?.message
							}}
						/>
					)}
					name='companyCurrencies'
					control={control}
				/>
			</Grid>

			<Grid item md={4} xs={12}>
				<Controller
					render={({ field, fieldState }) => (
						<AutocompleteField
							handleChange={field.onChange}
							handleBlur={field.onBlur}
							value={field.value}
							disabled={
								field.disabled ||
								isEmpty(values?.companyCurrencies)
							}
							name={field.name}
							options={
								!isEmpty(values?.companyCurrencies)
									? values?.companyCurrencies
									: []
							}
							label={t('company_primary_currency')}
							textFieldProps={{
								fullWidth: true,
								required: true,
								error: Boolean(fieldState?.error),
								helperText: fieldState?.error?.message
							}}
						/>
					)}
					name='companyPrimaryCurrency'
					control={control}
				/>
			</Grid>
		</Grid>
	);
}
